/******************************** Model Popup********************************/
.modal-dialog-centered {
  //max-width: 736px;
  .title {
    color: var(--color-extra02);
    font-weight: 500;
  }
}

.podcast-successfully {
  &.payment-successful {
    p {
      &.payment-desc {
        @media (max-width: 767px) {
          font-size: 18px !important;
        }
      }
    }

    .enhance-btn {
      &.max-unset {
        @media (max-width: 767px) {
          flex-direction: column;
        }
        max-width: unset;

        button {
          max-width: 289px;
          @media (max-width: 767px) {
            max-width: unset;
            width: 100% !important;
            &:not(:last-child) {
              margin-bottom: 20px;
            }
          }
        }
      }

      max-width: 335px;
      //margin: 48px auto 44px;
      @media (max-width: 767px) {
        margin: 20px auto;
      }
    }
  }

  .modal-content {
    border-radius: var(--radius);
  }
}

.podcast-successfully .modal-header {
  border: 0;
  justify-content: flex-end;
}

.podcast-successfully .btn-close {
  background: url('../svg/subscription/X.svg') no-repeat;
  opacity: 1;
  border-radius: 0;
  top: 40px;
  right: 40px;
  position: absolute;
  outline: none;
  box-shadow: none;
  cursor: pointer !important;
}

.podcast-successfully p {
  font-size: 32px;
  line-height: 2;
  padding: 40px 0 34px;
  max-width: 334px;
  margin: 0 auto;
  text-align: center;
}

.yes-no {
  background: rgb(146, 68, 235, 20%);
  border-radius: 16px;
  padding: 36px;
  max-width: 496px;
  margin: 0 auto;
  margin-bottom: 60px;
}

.yes-no p {
  text-align: center;
  font-size: 20px;
  line-height: 30px;
  padding: 0;
  max-width: unset;
}

.yes-no .enhance-btn {
  margin-top: 24px;
}

.box-width {
  &.admin-delete {
    max-width: unset !important;
    margin: 0 auto;
    padding: 64px 31px;

    p {
      &.admin-p-text {
        max-width: 488px !important;
      }
    }
  }

  max-width: 486px;
  margin: 0 auto;
  padding: 64px;
  @media (max-width: 767px) {
    padding: 20px 0;
  }
}

.podcast-successfully .box-width p {
  max-width: 486px !important;
}

.delete-popup {
  .modal-dialog-centered {
    max-width: 584px !important;
  }
}

.invite-popup {
  .modal-dialog-centered {
    max-width: 628px;

    .invite {
      margin: 40px 20px 30px;
      @media (max-width: 767px) {
        font-size: 28px;
      }
    }

    .modal-body {
      padding: 0 40px 40px 40px;

      .invite-box {
        max-width: unset !important;
        padding: 0;

        .form-style {
          .email-control {
            margin-bottom: 14px !important;

            div {
              padding: 10px 24px;
            }
          }

          textarea {
            padding: 16px 24px;
          }
        }
      }

      .btn-style {
        width: 200px !important;
      }
    }
  }
}

.podcast-successfully {
  .modal-invite {
    border: 0;
    justify-content: center;
    padding: 0 16px;
  }
}

.invite-box {
  max-width: 486px;
  margin: 0 auto;
  padding: 0 20px;
}

.email-control {
  border-radius: 12px !important;
  margin-bottom: 30px !important;

  div {
    background: #eeefff !important;
    border-radius: 12px !important;
    border: 0;
    padding: 15px 24px;
    color: #5d5962;
    outline: none;
    display: flex;
    align-items: center !important;
    justify-content: flex-start !important;

    span {
      top: 20px !important;
      left: 30px !important;
      padding: 0 !important;
    }

    input {
      background: #eeefff;
      border-radius: 5px;
      border: 0;
      margin: 5px 0 !important;
      padding: 0 10px !important;
      color: #5d5962;
      outline: none;
      display: flex;
      align-items: center !important;
      justify-content: center !important;
      font-size: 1rem !important;
    }

    input:focus {
      color: #1b0730;
    }

    div {
      div {
        background-color: #8e92db61 !important;
        font-size: 1rem !important;
      }
    }
  }

  div:focus {
    border: 1px solid #9244eb !important;
    color: #1b0730;
  }
}

@media (max-width: 767px) {
  .podcast-successfully.delete-popup img {
    width: 60px;
  }
}

.modal-wrapper {
  .modal-content {
    .plan-upgrade-modal.modal-body {
      padding: 40px !important;

      & > svg {
        width: 100%;
      }

      .enhance-btn {
        margin-bottom: 0 !important;
      }
    }
  }
}

.modal-wrapper {
  &.modal-submit {
    &.is-light-modal {
      color: #ffffff;
      .modal-content {
        background: rgba(90, 54, 144, 0.7);
      }

      .form-control {
        color: #ffffff !important;

        &::placeholder {
          color: #aaaaaa;
        }
      }
    }
    .modal-content {
      background: rgba(90, 54, 144, 0.5);
      border: 1px solid #5a3690;
    }
  }

  .save-btn {
    font-weight: 500;
  }
  &.modal-delete {
    &.is-light-modal {
      .modal-content {
        background: rgba(255, 15, 123, 0.7);
      }
    }
    .modal-content {
      background: rgba(255, 15, 123, 0.4);
      border: 1px solid #ff0f7b;
    }
  }

  .modal-body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    gap: 10px;
  }
  .payment-desc {
    color: #bdbaba !important;
    font-size: 16px;
  }
  .title {
    color: var(--color-white);
    text-align: center;
  }
  img {
    width: 60px;
    margin-bottom: 10px;

    &.crop-image-wrapper {
      width: unset;
    }
  }

  .modal-close-btn {
    svg {
      width: 15px;
      path {
        fill: var(--color-white);
      }
    }
  }
  .save-btn {
    background-color: var(--color-white) !important;
    color: var(--color-secondary) !important;
    &:hover {
      color: var(--color-secondary) !important;
    }
  }
  .close-btn {
    border: 1px solid var(--color-white);
    color: var(--color-white);
    background-color: transparent !important;
    &:hover {
      color: var(--color-white) !important;
      background-color: transparent;
    }
  }
  .close-btn,
  .save-btn {
    min-width: 132px;
  }
}
