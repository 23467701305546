.register-logo {
  margin: 110px 0 30px !important;
}
.or {
  &::before,
  &::after {
    content: '';
    display: block;
    border-top: solid 1px var(--color-primary-1);
    width: 44%;
    margin: 0 auto;
    height: 1px;
    position: absolute;
    top: 50%;
    z-index: 1;
  }
  &::before {
    left: 0;
  }
  &::after {
    right: 0;
  }
  margin: 4px 0 14px;
  color: var(--color-primary-3);
  > span {
    padding: 0 10px;
    position: relative;
    z-index: 5;
  }
}
.social-media-btn {
  &:not(:last-child) {
    margin-bottom: 16px;
  }
  padding: 14px 20px;
  border: 1px solid var(--color-primary);
  border-radius: 12px;
  margin: 0 auto;
  text-align: center;
  color: var(--color-body);
  .mr-10 {
    margin-right: 10px;
    display: flex;
    align-items: center;
  }
}

.login-style {
  &.change-pass {
    display: unset;
  }
  width: 50%;

  h1 {
    font-weight: 300;
    font-size: 30px;
    color: var(--color-primary);
  }

  p {
    font-size: 30px;
    line-height: 46px;
    color: #5d5962;
  }
  .countdown {
    font-size: 14px;
    display: flex;
    justify-content: center;
    gap: 5px;
    .sec {
      color: #9244eb;
    }
  }
}
.create-profile {
  .three-box {
    [class*='col-']:not(:last-child) {
      padding-right: 0px;
      @media (max-width: 768px) {
        .radio-btn {
          margin-bottom: 16px;
        }
      }
    }
  }
  padding: 40px 0 0;
  span {
    color: #5d5962;
    font-size: 13px;
  }

  .from-group-wrapper {
    label:not(.radio-btn label) {
      font-size: 16px;
    }
    @media (max-width: 992px) {
      .error {
        bottom: -20px;
      }
    }
  }

  h1 {
    font-weight: 300;
    font-size: 54px;
    line-height: 83px;
    color: #1b0730;
  }
  p {
    font-size: 30px;
    line-height: 46px;
    color: #5d5962;
  }
  .mb-59 {
    margin-bottom: 43px;
  }
  .form-style {
    max-width: 820px;
    margin: 0 auto;
    .profile-pic-container {
      max-width: fit-content;
    }
    .profile-img {
      display: block;
      width: 100%;
    }

    button[type='submit'] {
      //margin-left: 25.5%;
      //width: 312px !important;
      //@media (max-width: 1200px) {
      //  margin-left: 34%;
      //}
      //@media (max-width: 991px) {
      //  margin-left: 0;
      //  width: 100% !important;
      //}
    }
    .save-button {
      width: 160px !important;
    }
  }
  .col-xl-9.col-lg-8 > .row:last-child {
    .from-group-wrapper {
      .error {
        bottom: -5px;
      }
    }
  }
}

.body-layout {
  background: url('../svg/login/BG1.svg') center top/cover no-repeat;
  min-height: calc(100vh - 188px);
}
.max-width-335 {
  max-width: 335px;
  margin: 0 auto;
}

.login-style {
  label {
    font-family: AvenirArabic-Heavy;
    font-size: 16px;
    line-height: 24px;
    color: #1b0730;
    margin-bottom: 12px;
  }
}

.py-10 {
  padding: 10px 0;
}

.form-control::-webkit-input-placeholder {
  /* Edge */
  font-family: AvenirArabic-Medium;
  font-size: 16px;
  line-height: 24px;
  color: #797ba1;
}

.form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-size: 16px;
  line-height: 24px;
  color: #797ba1;
}

.position {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 62%;
  right: 16px;
}

.auth-link {
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 0.02em;
  text-decoration-line: underline;
  color: #9244eb;
  font-family: AvenirArabic-Medium;
}

@media (max-width: 1199px) {
  .mt-110 {
    margin: 50px 0;
  }
}

@media (max-width: 991px) {
  .login-style {
    width: 100% !important;
  }

  .login-style,
  .create-profile {
    h1 {
      font-size: 30px;
      line-height: normal;
    }
  }

  .login-style,
  .create-profile {
    p {
      font-size: 22px;
      line-height: normal;
    }
  }
}

.login-form-container {
  .register-logo,
  .mt-110 {
    margin: 70px 0 27px !important;
  }
}

.login-form {
  margin-bottom: 20px;
}

@media (max-width: 767px) {
  .login-style img {
    max-width: 200px;
  }
  .three-box {
    [class*='col-'] {
      padding-right: 12px !important;
    }
  }
  .mb-59 {
    margin-bottom: 30px !important;
  }
}

.facebook-login-container {
  background: transparent;
  border: none;
  padding: 0;
  width: 100%;
  margin-bottom: 16px;
}

.send-email-podcaster {
  .send-email-podcaster-btn {
    color: blue !important;
    background: transparent !important;
    padding: 0;
    border: none;
    text-decoration: underline;
  }
}

.join-podcaster-modal {
  &.modal-wrapper {
    .modal-content {
      max-width: 600px;

      .modal-body {
        padding: 16px 40px 16px !important;
        p {
          padding: 40px 0px 20px;
        }
        .email {
          color: #9244eb;
        }
        .enhance-btn {
          margin: 40px auto;
        }
      }
    }
  }
}
.reset-password-info {
  .info-icon {
    top: 0px;
    left: 35%;
  }
}
