/*
    Card Styles  
*/

.card-box {
  &.card-style-1 {
    box-shadow: var(--shadow-lighter);
    background-color: var(--color-blackest);
    padding: 20px;
    border-radius: 5px;
    margin-top: 30px;
  }

  .inner {
    .image {
      a {
        img {
          border-radius: 5px;
        }
      }
    }

    .content {
      padding-top: 20px;

      .title {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 14px;

        a {
          @extend %transition;
          color: inherit;

          &:hover {
            color: var(--color-primary);
          }
        }
      }

      .description {
        margin-bottom: 0;
      }

      .btn-default {
        margin-top: 20px;
      }
    }
  }
}

// Bg flashlight
.bg-flashlight {
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: var(--y);
    left: var(--x);
    transform: translate(-50%, -50%);
    background: radial-gradient(var(--color-secondary), transparent, transparent);
    width: 200%;
    height: 200%;
    opacity: 0;
    transition:
      0.5s,
      top 0s,
      left 0s;
    border-radius: var(--radius);
  }

  &:hover {
    &::before {
      opacity: 1;
    }
  }

  &::after {
    content: '';
    position: absolute;
    inset: 2px;
    background: var(--color-blackest);
    border-radius: var(--radius);
  }

  & > * {
    z-index: 2;
    position: relative;
  }

  &.bg-secondary {
    &::before {
      background: radial-gradient(var(--color-secondary), transparent, transparent);
    }
  }

  &.bg-tertiary {
    &::before {
      background: radial-gradient(var(--color-tertiary), transparent, transparent);
    }
  }
}

// Bg flashlight Static
.bg-flashlight-static {
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: linear-gradient(to bottom, var(--color-primary), rgba(39, 39, 55, 0.2));
    opacity: 0.2;
    width: 100%;
    height: 100%;
    transition:
      0.5s,
      top 0s,
      left 0s;
    border-radius: var(--radius);
    transition: all 0.3s ease-in-out;
  }

  &::after {
    content: '';
    position: absolute;
    inset: 2px;
    background: var(--color-blackest);
    border-radius: var(--radius);
    transition: all 0.3s ease-in-out;
  }

  & > * {
    z-index: 2;
    position: relative;
  }

  &:hover {
    &::before {
      opacity: 1;
    }
  }

  &.bg-secondary {
    &::before {
      background: radial-gradient(var(--color-secondary), transparent, transparent);
    }
  }

  &.bg-tertiary {
    &::before {
      background: radial-gradient(var(--color-tertiary), transparent, transparent);
    }
  }
}

.blur-flashlight {
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: var(--y);
    left: var(--x);
    transform: translate(-50%, -50%);
    background: radial-gradient(
      650px at var(--x) var(--y),
      rgba(227, 227, 227, 0.1),
      transparent 50%
    );
    width: 200%;
    height: 200%;
    opacity: 0;
    transition:
      0.5s,
      top 0s,
      left 0s;
    border-radius: var(--radius-big);
  }

  &:hover {
    &::before {
      opacity: 1;
    }
  }

  &::after {
    content: '';
    position: absolute;
    background: var(--color-blackest);
    border-radius: var(--radius);
  }

  & > * {
    z-index: 2;
    position: relative;
  }
}

body.active-dark-mode.modal-open {
  padding-right: 0 !important;
}
