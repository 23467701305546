.upload-file-wrapper {
  .image-view {
    border-radius: 12px;
    height: 100%;
    width: 100%;
    object-fit: fill;

    &.thumbnail-bg {
      max-width: 100%;
      height: 100%;
      width: 100%;
    }
  }

  .error {
    top: unset;
  }
}
