$input-padding-y: 1.5rem;
$input-border-color: var(--color-primary-3);
$input-focus-border-color: var(--color-primary);
$form-select-focus-border-color: var(--color-primary);
$form-select-focus-width: 2px;
$input-focus-width: 3px;
$input-bg: transparent !important;
$input-color: var(--color-input) !important;
$input-border-radius: var(--radius);
$form-select-color: var(--color-input);
$form-select-font-size: 1.4rem;
$input-font-size: 1.6rem;
$input-padding-y: 12px;
$input-padding-x: 15px;
$input-font-weight: 500;
$form-select-font-weight: 500;

$custom-select-bg: #ff6600;
$custom-select-focus-bg: #ffcc99;
$custom-select-disabled-bg: #f5f5f5;
