.management-podcast-wrapper {
  min-height: calc(100vh - 80px);

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .steps-wrapper {
    overflow-x: auto;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;

    margin-bottom: 80px;

    @media (max-width: 1267px) and (min-width: 1200px) {
      .mx-5 {
        margin: 0 15px !important;
      }
    }
    @media (max-width: 1199px) {
      .mx-5 {
        margin: 0 15px !important;
      }
      .management-steps-label {
        font-size: 16px !important;
      }
    }
    @media (max-width: 822px) {
      display: none !important;
    }
  }

  form {
    flex: 1;
    display: flex;
    flex-direction: column;

    .form-body {
      flex: 1;
    }
  }
  .management-steps-label {
    font-weight: 500;
    font-size: 18px;

    color: var(--color-extra02);

    &.active {
      color: var(--color-secondary);
      font-weight: 500;
    }
    &.pre-active {
      color: var(--color-white);
      font-weight: 500;
    }
  }

  .form-actions {
    position: sticky;
    bottom: 0;
    padding: 13px;
    background-color: var(--color-dark);

    button {
      font-size: 16px !important;
      background: transparent !important;
      color: var(--color-primary-3);
      img {
        width: 14px;
      }

      &.wizard-btn {
        padding: 0;
        font-size: 20px !important;

        &.btn-style:hover {
          color: var(--color-primary) !important;
        }
      }

      .back-btn {
        color: var(--color-purple);
        border: 1px solid var(--color-purple);
        background-color: transparent;
        &:hover {
          background-color: var(--color-purple-light);
          color: var(--color-white);
        }
      }

      .next-btn {
        color: var(--color-white);
        background-color: var(--color-purple);
        border: none;
        &:hover {
          background-color: var(--color-purple-light);
        }
      }
    }
  }

  .upload-audio-wrapper {
    background: url('../../../../../../assets/svg/dashboard/Rectangle.svg') center / contain
      no-repeat;
    display: flex;
    flex-direction: row;
    padding: 50px !important;
    height: 210px;

    width: 800px;

    @media only screen and (max-width: 869px) {
      background: url('../../../../../../assets/svg/dashboard/Rectangle-1.svg') center / contain
        no-repeat;
      max-width: 375px;
      width: 300px;
      height: 335px;
      padding: 40px !important;
      flex-direction: column !important;
      .d-flex.justify-content-center.align-items-center.gap-5,
      button.upload-audio-wrapper.drop-zone.d-flex.justify-content-center.align-items-center {
        flex-direction: column !important;
      }
      button.upload-audio-wrapper {
        flex-direction: column !important;
      }
      span.drop-zone__prompt.d-block.text-left.drop-zone-note {
        font-size: 12px !important;
      }
    }
    //margin: auto;

    &.thumbnail-bg {
      background: url('../../../../../../assets/svg/dashboard/Rectangle-1.svg') center / contain
        no-repeat;
      max-width: 375px;
      width: 300px;
      height: 335px;
      padding: 40px !important;

      > div {
        flex-direction: column;
        gap: 14px !important;
        img {
          width: 70px;
        }
        .drop-zone-note {
          text-align: center !important;
          font-size: 16px !important;
        }
      }
    }
  }
}

.review-wrapper {
  span {
    margin-bottom: 4px;
    font-weight: 500;
    font-size: 16px;
    text-transform: uppercase;
    color: var(--color-primary-3);
  }
  .images {
    position: relative;
    margin-bottom: 20px;
  }
  img.thumbnail-bg {
    width: 240px;
    height: 100%;
    display: block;
    z-index: 9;
    position: absolute;
    top: 0;
    padding: 20px;
    margin: 0;
    border-radius: 30px;
  }
  .image-view.background-bg {
    width: 100%;
    height: 228px;
    background-size: cover;
    background-repeat: no-repeat;
  }
  p {
    margin: 0;
    font-size: 20px;
    font-weight: 500;
  }
  p.description {
    color: var(--color-white);
    width: 80%;
    font-weight: 400;
    //@media #{$small-mobile} {
    //  width: 100%;
    //}
  }
}

.bg-image {
  max-width: 375px;

  width: 260px;
  height: 320px;
}

.background-review {
  background-color: var(--color-primary);
}

.form-margin .react-datepicker__input-container {
  .form-control {
    margin-bottom: 0 !important;
  }
}

.rtl-steps {
  .management-steps-label {
    text-align: right; // Ensure labels are aligned correctly
  }

  .arrow-rtl {
    transform: rotate(180deg); // Flip the arrow direction
  }

  // Adjust spacing if needed
  .mx-5 {
    margin-left: 0;
    margin-right: 20px; // Or any desired spacing for RTL mode
  }
}

.rtl-actions {
  flex-direction: row-reverse; /* Reverse button order */

  .back-btn {
    margin-left: auto; /* Align "Back" button to the right edge */
    margin-right: 0;
  }

  .next-btn {
    align-self: flex-start; /* Align to the left edge explicitly */
    margin-left: 0; /* Optional: To ensure it doesn't have unnecessary margin */
  }
}
