.subscription-gap {
  &.ads-free-plans {
    .subscription-block {
      &.premium-bg {
        .features {
          ul {
            li {
              &::before {
                content: url('../svg/subscription/SubscriptionCheck.svg') !important;
              }
            }
          }
        }
      }

      padding: 40px 24px 24px 24px;

      .plan-name {
        margin-bottom: 20px !important;
      }

      .price-block {
        h2 {
          font-size: 36px;
        }
      }

      .features {
        ul {
          height: 152px;

          li {
            font-size: 14px;
          }
        }
      }

      .features {
        ul {
          li {
            &.skip-arrow {
              &::before {
                content: url('../svg/subscription/Cancel.svg') !important;
              }
            }
          }
        }
      }
    }
  }

  row-gap: 20px;

  .subscription-block {
    button {
      padding: 0 !important;
    }

    .reject-btn.rebtn {
      margin-top: 12px;
    }

    &.subscription-bg {
      background: #eeefff;

      &.selected-user-plan {
        border: 2px solid #9445ee;

        .reject-btn.rebtn {
          margin-top: 12px;
          background: #ffffff !important;

          .bar {
            background-color: #9244eb !important;
          }

          &:hover,
          &:focus {
            color: #1b0730 !important;
          }
        }

        .renew-btn {
          background: #e9dafb !important;
          color: #9244eb !important;
          border: 0 !important;

          &:disabled {
            opacity: 1 !important;
          }
        }
      }

      &.user-select-plan {
        background-color: #ffffff;
        background-image: url('../svg/subscription/BG4.svg');

        h1 {
          &::before {
            position: absolute;
            content: url('../svg/subscription/Crawn.svg');
            top: -23px;
            left: -15px;
          }

          position: relative;
        }
      }
    }

    &.premium-bg {
      background: url('../svg/subscription/BG3.svg') center center/cover no-repeat;
      color: #ffffff !important;
      border: 0;

      p,
      sup {
        color: #ffffff !important;
      }

      h1 {
        &::before {
          position: absolute;
          content: url('../svg/subscription/CrawnYallow.svg');
          top: -23px;
          left: -15px;
        }

        position: relative;
      }

      h2 {
        color: #e2f952 !important;
      }

      .btn-style {
        background: #ffffff !important;
        color: #1b0730;

        &:hover {
          color: #1b0730 !important;
        }
      }

      ul {
        li {
          &::before {
            content: url('../svg/subscription/CheckYallow.svg') !important;
          }
        }
      }
    }

    padding: 40px 30px 24px;
    @media (max-width: 1399px) and (min-width: 1199px) {
      padding: 40px 16px 24px;
    }
    border: 2px solid #cfd1fc;
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    overflow: hidden;
    background: #ffffff;
    height: 100%;

    .popular {
      height: 52px;
      background-color: #e2f952;
      font-family: AvenirArabic-Heavy;
      border: 1px solid #1b0730;
      font-size: 20px;
      font-weight: 800;
      transform: rotate(36.424deg);
      text-transform: uppercase;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #5d5962;
      position: absolute;
      right: -46px;
      width: 208px;
      top: 15px;
    }

    .plan-name,
    .price-block {
      margin-bottom: 16px;

      h1,
      p {
        margin: 0;
        font-family: AvenirArabic-Medium;
      }

      h1 {
        font-size: 28px;
        line-height: 40px;
      }

      p {
        color: #5d5962;
        line-height: 24px;
      }
    }

    .price-block {
      margin-bottom: 32px;

      p {
        font-size: 14px;
      }

      h2 {
        font-size: 36px;
        line-height: 50px;
        color: #9244eb;
        font-family: AvenirArabic-Heavy;
        margin: 0;

        sup {
          color: #1b0730;
          font-size: 18px;
          line-height: 20px;
          top: -2px;
          left: 8px;
        }
      }
    }

    .features {
      margin-bottom: 30px;

      p {
        font-size: 14px;
        color: #5d5962;
        margin-bottom: 8px;
        line-height: 24px;
      }

      ul {
        padding-left: 1.4rem;
        margin-bottom: 0;
        position: relative;

        li {
          &:not(:last-child) {
            margin-bottom: 10px;
          }

          &::before {
            position: absolute;
            left: 0px;
            content: url('../svg/subscription/Check.svg');
          }

          ::marker {
            all: unset;
          }
          font-size: 16px;
          line-height: 24px;
          padding-left: 0.5em;
        }
      }
    }
  }
}

.feature-item {
  text-align: left;
  display: flex;
  align-items: center;
}

[dir='rtl'] .feature-item {
  text-align: right;
}

.make-payment-main {
  @media (max-width: 1199px) {
    @media (max-width: 787px) {
      flex-direction: column;
      .w-50 {
        width: 100% !important;
      }
    }
    .selected-plan {
      &.subscription-gap {
        .subscription-bg {
          @media (max-width: 787px) {
            border-radius: 0 !important;
            padding: 20px !important;
          }
          padding: 50px !important;

          .popular {
            right: -101px !important;
            height: 40px !important;
            top: 28px !important;
            font-size: 20px !important;
          }

          .plan-name {
            h1 {
              font-size: 40px !important;
            }

            p {
              font-size: 20px !important;
            }
          }

          .price-block {
            h2 {
              font-size: 50px !important;

              sup {
                font-size: 16px !important;
              }
            }

            p {
              font-size: 18px !important;
            }
          }

          .features {
            p {
              font-size: 18px !important;
            }

            ul {
              li {
                font-size: 20px !important;
              }
            }
          }
        }
      }
    }
    .payment-details {
      @media (max-width: 787px) {
        padding: 20px !important;
      }
      padding: 50px !important;

      h1 {
        font-size: 40px;
      }
    }
  }

  .selected-plan {
    &.subscription-gap {
      .subscription-bg {
        .plan-name {
          h1 {
            font-size: 52px;
            color: #1b0730;
            line-height: normal;
          }

          p {
            font-size: 30px;
            line-height: normal;
          }
        }

        .price-block {
          h2 {
            font-size: 80px;

            sup {
              font-size: 30px;
              top: -2px;
            }
          }

          p {
            font-size: 24px;
            line-height: 32px;
            margin-top: 20px;
          }
        }

        .features {
          p {
            font-size: 22px;
            line-height: 36px;
          }

          ul {
            li {
              font-size: 24px;
              line-height: 36px;
            }
          }
        }
      }
    }
  }
}

.payment-details {
  h1 {
    text-align: center;
    font-size: 54px;
    margin-bottom: 42px;
  }

  select {
    &.form-control {
      background-image: url('../svg/subscription/Arrows.svg') !important;
    }
  }

  .card-logo {
    &::before {
      content: url('../svg/subscription/MasterCard.svg');
      position: absolute;
      top: 17px;
      right: 14px;
    }
  }
}

.total-sub-total {
  margin: 34px 0 40px 0;

  .border-1 {
    border-top: 1px solid #bcbdd7;
  }

  .grey-text {
    + div {
      font-family: AvenirArabic-Heavy;
      color: #1b0730;
    }

    color: #5d5962;
  }
}

.zeropodcast {
  padding: 127px 0 0;

  p {
    font-size: 54px;
    line-height: 84px;
    font-family: AvenirArabic-Light;
    margin-bottom: 40px;
  }

  @media (max-width: 1199px) {
    padding: 50px 0 0;
    p {
      font-size: 33px;
      line-height: normal;
      margin-bottom: 0;
    }
  }
}

.form-margin {
  .drop-zone {
    margin-bottom: 16px !important;
  }

  .when-publish {
    font-size: 16px;
    line-height: 24px;
    margin: 30px 0 16px;
    color: #1b0730;
  }
}

.download-last-record {
  button {
    max-width: 185px;
    margin: 0 auto;
    background-color: var(--color-bg-2) !important;
    color: var(--color-white) !important;
  }
}

.big-height {
  .press-record {
    &.max-file {
      font-size: 24px !important;
      line-height: 24px;
      color: #1b0730;
      font-family: AvenirArabic-Heavy;
    }
  }
}

.edit-btn {
  &.mt-25 {
    top: 25px;
    right: 15px;
  }

  cursor: pointer;
  @media (max-width: 767px) {
    svg {
      width: 28px;
    }
  }
}

.nav-link {
  color: #ffffff;
  font-size: 16px;
  line-height: 24px;
  width: fit-content;

  svg path {
    fill: var(--color-primary-3);
  }
}

@media (max-width: 991px) {
  .dropdown {
    &.profile-width {
      width: 100%;

      .dropdown-menu {
        width: 100%;
      }
    }
  }
  .episode-details-container {
    &.w-75 {
      width: 68% !important;
      @media (max-width: 767px) {
        width: 100% !important;
        margin-top: 10px;
      }

      .w-100 {
        width: 86% !important;
        @media (max-width: 767px) {
          width: 100% !important;
        }
      }
    }
  }
}

.podcast-details-view {
  padding: 36px;
  margin-bottom: 0;
  @media (max-width: 991px) {
    padding: 16px;
  }

  .img-block {
    min-width: 240px;
    margin-right: 40px;

    .podcast-detail-thumbnail {
      height: 250px;
      width: 100%;
      object-fit: cover;
    }

    @media (max-width: 991px) {
      margin-right: 0px;
    }

    img {
      border-radius: 24px;
    }

    .views-number {
      background: #e3dcff;
      border-radius: 10px;
      margin-top: 22px;
      padding: 10px;

      h2 {
        font-family: AvenirArabic-Heavy;
        margin: 0;
        font-size: 20px;

        span {
          font-size: 20px;
          margin-left: 4px;
        }
      }

      .border-right {
        border-right: 1px solid #797ba1;
      }

      p {
        color: #5d5962;
        font-family: AvenirArabic-Medium;
      }
    }
  }

  .podcast-details-box {
    flex: 1;

    h1 {
      @media (max-width: 767px) {
        font-size: 28px;
      }
      font-size: 42px;
      color: #1b0730;
      line-height: 50px;
      font-family: AvenirArabic-Heavy;
    }

    .time {
      margin: 0 12px;
      position: relative;

      &::before {
        position: absolute;
        content: '';
        height: 8px;
        width: 8px;
        background-color: #797ba1;
        top: 50%;
        transform: translate(-50%, -50%);
        left: -12px;
        border-radius: 50%;
      }
    }

    p {
      color: #5d5962;
      line-height: 24px;

      span {
        font-family: AvenirArabic-Heavy;
      }
    }

    .icon-list {
      margin: 21px 0 37px;

      div {
        margin: 3px 0;

        &:not(:last-child) {
          margin-right: 14px;
        }
      }
    }

    .detalis-scroll-view {
      height: 84px;
      //font-size: #1b0730;
      line-height: 24px;
      overflow-y: auto;
      overflow-x: hidden;
    }

    .monetize-checkbox {
      .monetize-check {
        position: relative;
        background: #9244eb;
        padding: 10px 8px;
        border-radius: 8px;
        height: 40px;
        display: flex;
        align-items: center;

        input[type='checkbox'] {
          + label {
            color: #ffffff;
            margin-left: 15px;
          }

          + label::after {
            transform: translate(-50%, 8%);
            left: 10px !important;
            content: url('../svg/subscription/BG6.svg');
          }

          &:checked + label::after {
            content: url('../svg/subscription/CheckActiveYallaow.svg');
          }
        }
      }

      div {
        margin: 3px 0;
        cursor: pointer;

        //&:not(:last-child) {
        //  padding-bottom: 10px;
        //}
      }
    }
  }
}

.tabs {
  &.transaction-history {
    &.admin-transaction-history {
      .nav-pills {
        .nav-item {
          width: 25%;
        }
      }
    }

    .nav-pills {
      .nav-item {
        width: 33.33%;
      }
    }
  }

  .nav-pills {
    border-radius: 12px;
    border: 1px solid #797ba1;
    background: #fff;
    padding: 6px;

    .nav-item {
      width: 50%;

      .nav-link {
        width: 100%;
        font-family: AvenirArabic-Heavy;
        color: #5d5962;
        text-align: center;
        font-size: 20px;
        line-height: normal;
        padding: 10px 0;

        &.active {
          border-radius: 12px;
          background: #eeefff;
          color: #9244eb;
        }
      }
    }
  }

  .tab-content {
    // .dropdown {
    //   &.dropstart {
    //     .dropdown-menu {
    //       transform: translate(-92px, 0px) !important;
    //     }
    //   }
    // }

    .revenue-analytics {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(31%, 1fr));
      grid-gap: 16px;
      margin-top: 50px;
      margin-bottom: 30px;

      .digit-box {
        border-radius: 20px;
        background: #eeefff;
        padding: 32px;

        p {
          &.about-text {
            color: #5d5962;
            font-size: 16px;
            line-height: 20px;
            font-family: AvenirArabic-Medium;
          }

          color: #5d5962;
          font-family: AvenirArabic-Medium;
          font-size: 24px;
          line-height: 30px;
        }

        h1 {
          font-family: AvenirArabic-Heavy;
          font-size: 42px;
          color: #1b0730;
          line-height: 50px;
          margin: 10px 0 0;

          svg {
            margin-left: 12px;
          }
        }
      }
    }

    .view-download {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(31%, 1fr));
      grid-gap: 16px;

      .view-download-box {
        border-radius: 20px;
        border: 1px solid rgba(121, 123, 161, 0.54);
        background: #fff;
        padding: 24px;

        .title {
          color: #5d5962;
          font-size: 20px;
          font-family: AvenirArabic-Heavy;
          line-height: 22px;
        }

        svg {
          margin-right: 10px;
        }

        select {
          min-width: 140px;
          height: 42px;
          padding: 10px 16px;
          font-size: 14px;
          font-family: AvenirArabic-Heavy;
          color: #5d5962;
          background-position: right 1rem center !important;
        }
      }
    }
  }
}

@media (max-width: 1199px) {
  .tabs {
    .nav-pills {
      .nav-item {
        @media (max-width: 767px) {
          width: 100% !important;
        }

        .nav-link {
          font-size: 20px;
          line-height: normal;
        }
      }
    }

    .tab-content {
      .revenue-analytics {
        @media (max-width: 991px) {
          grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
        }

        .digit-box {
          padding: 20px;

          p {
            font-size: 16px;
          }

          h1 {
            font-size: 34px;
            margin: 0;
          }
        }
      }

      @media (max-width: 991px) {
        .view-download {
          grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
        }
      }
    }
  }
}

.view-transcript {
  a,
  span {
    color: #9244eb;
    font-size: 24px;
    text-decoration-line: underline;
    font-family: AvenirArabic-Heavy;
  }
}

.editor-audio-page {
  margin-top: 87px;

  .menu-close-icon {
    svg {
      top: 50%;
      transform: translate(-50%, -50%);
      position: absolute;
    }

    position: relative;
    cursor: pointer;
  }

  .editor-audio-menu {
    &.p-0 {
      min-height: unset;

      .dropdown-toggle {
        //height: 54px;
        color: #797ba1;
        font-size: 16px;
        line-height: 24px;
        padding: 15px 20px;
        @media (max-width: 991px) {
          justify-content: center;
          width: 100%;
          margin: 0;
          margin-bottom: 20px;
        }

        &::after {
          margin-left: 1.455em;
          content: '';
          border-top: 0.5em solid;
          border-right: 0.4em solid transparent;
          border-bottom: 0;
          border-left: 0.4em solid transparent;
          color: #5d5962;
        }
      }
    }

    padding: 0 70px 0 30px;
    min-height: 76px;

    .project-select {
      .border-right-b-d {
        border-right: 1px solid rgba(121, 123, 161, 0.24);
        margin: 0 16px;
      }

      .dropdown-toggle {
        border: 0;
        background: transparent;
        padding: 0;
        color: #1b0730;
        font-family: AvenirArabic-Heavy;
        font-size: 18px;

        &::after {
          margin-left: 0.455em;
          content: '';
          border-top: 0.4em solid;
          border-right: 0.4em solid transparent;
          border-bottom: 0;
          border-left: 0.4em solid transparent;
          color: #5d5962;
        }
      }

      li {
        &.scroll-bar-li {
          max-height: 180px;
          overflow: auto;
        }

        div {
          &:not(:last-child) {
            margin-bottom: 10px;
          }
        }
      }
    }

    .dropdown-toggle {
      &::after {
        border: 0;
        margin: 0;
      }

      border-radius: 10px;
      border: 1px solid #797ba1;
      background: transparent;
      padding: 15px 12px;
      color: #5d5962;
      text-align: center;
      font-size: 14px;
      font-family: AvenirArabic-Heavy;
      line-height: 20px;
      height: 50px;

      svg {
        margin-right: 12px;
      }
    }

    .dropdown-menu {
      color: #5d5962;
      font-size: 14px;
      line-height: 20px;
      border-radius: 12px;
      background: var(--color-dark-1);
      box-shadow: 0 4px 24px 0 #e9ddf6;
      border: 0;
      min-width: 200px;
      padding: 0;

      &::after {
        content: var(--dorp-right-arrow);
        position: absolute !important;
        left: 30px;
        top: -22px;
        transform: rotate(270deg);
      }

      svg {
        margin-right: 10px;
        height: 20px;
        width: 20px;
      }

      .dropdown-item {
        &:hover,
        &:focus {
          background: transparent;
        }

        padding: 0;
        color: var(--color-white);
      }

      .border-bottom-b-d {
        border-bottom: 1px solid rgb(121 123 161 / 24%);
        //margin: 12px 0;
      }

      &.export-as {
        min-width: 422px;

        .format {
          .btn-style {
            height: 50px;
            font-size: 12px;
            height: 36px;
          }

          p {
            color: #1b0730;
            font-family: AvenirArabic-Medium;
            font-size: 14px;
            line-height: 20px;
          }

          .export-span {
            border-radius: 5px;
            border: 1px solid #797ba1;
            background: #fff;
            padding: 9px 12px;
            height: 25px;
            display: flex;
            align-items: center;
            cursor: pointer;

            &.active {
              color: #fff;
              border: 1px solid transparent;
              background: linear-gradient(
                90deg,
                #9445ee -0.01%,
                #8d43e3 14.99%,
                #7b3ec7 40.99%,
                #5f3799 75%,
                #483274 100%
              );
            }

            &.kbps {
              border-radius: 5px;
              border: 1px solid #e2f952;
              background: rgba(226, 249, 82, 0.2);
              color: #9244eb;
              position: relative;

              &::before {
                content: url('../svg/subscription/SubscriptionCrawn.svg');
                position: absolute;
                right: -4px;
                top: -14px;
              }
            }
          }
        }
      }

      .search-bar {
        .input-group {
          &::before {
            content: url('../svg/subscription/Search.svg');
            left: 16px;
            top: 42%;
          }

          margin: 0;

          .form-control {
            &::placeholder {
              font-size: 10px;
            }

            height: 31px;
            margin-right: 0;
            border-radius: 6px;
            font-size: 10px;
            padding: 0 15px 0 30px;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}

.import-audio {
  .modal-dialog-centered {
    max-width: 548px;

    .drag-drop {
      max-width: 428px;
      height: 188px;
      padding: 16px;
      gap: 20px;
      border-radius: 20px;
      border: 1px dashed #797ba1;
      background: #eeefff;
      margin: 50px auto;
      @media (max-width: 767px) {
        margin: 20px auto;
      }

      p {
        color: #5d5962;
        font-size: 20px;
        line-height: 20px;
        font-family: AvenirArabic-Medium;
        padding: 0;
        @media (max-width: 767px) {
          font-size: 16px !important;
        }

        input {
          display: none;
        }

        label {
          color: #9244eb;
          text-decoration-line: underline;
          cursor: pointer;
        }
      }
    }
  }
}

.listener-btn {
  gap: 16px;

  .editor-audio-page {
    @media (max-width: 991px) {
      width: 50%;
      @media (max-width: 575px) {
        width: 100%;
      }
    }
  }
}

.listener-slider {
  .splide__arrows {
    .splide__arrow {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);

      // Default positioning for LTR (Left-to-Right)
      &.splide__arrow--prev {
        right: 30px; // Default left positioning
      }

      &.splide__arrow--next {
        right: -20px; // Default right positioning
      }

      // Adjust positions for RTL (Right-to-Left)
      .rtl &.splide__arrow--prev {
        left: 170px; // Move left arrow to the right for RTL
        right: unset;
      }

      .rtl &.splide__arrow--next {
        left: 220px; // Move right arrow to the left for RTL
        right: unset;
      }
    }
  }
}

.you-may-also-like-follow-container {
  .splide__arrows {
    .splide__arrow {
      .rtl &.splide__arrow--prev {
        left: 220px;
        right: unset;
      }

      .rtl &.splide__arrow--next {
        left: 170px;
        right: unset;
      }
    }
  }
}

.subscribe-main-bg {
  padding: 10px 0 40px;

  img {
    border-radius: 24px;
  }
}

.like-share {
  min-width: 116px;
}

.max-w-title {
  p {
    max-width: 582px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    white-space: unset;
    overflow: hidden;
  }
}

.comments-block {
  p {
    &.all-comments {
      color: #1b0730;
      font-size: 14px;
      line-height: 20px;
      font-family: AvenirArabic-Medium;
    }

    color: #1b0730;
    font-size: 16px;
    line-height: 24px;
    font-family: AvenirArabic-Heavy;
  }

  .comments-user {
    margin: 14px 0 18px;

    img {
      height: 50px;
      width: 50px;
      border-radius: 25px;
      margin-right: 15px !important;
    }

    .position-relative {
      &.w-100 {
        max-width: 503px;
      }

      svg {
        position: absolute;
        top: 50%;
        left: 30px;
        transform: translate(-50%, -50%);
      }

      input {
        max-width: 503px;
        height: 50px;
        border-radius: 25px;
        border: 1px solid #1b0730;
        background: #e2f952;
        padding-left: 49px;
        padding-right: 50px;
        color: #1b0730;
        font-size: 14px;

        &::placeholder {
          color: #1b0730;
          font-size: 14px;
        }
      }

      .msg-sent-icon {
        svg {
          right: -16px;
          left: unset;
        }
      }
    }
  }

  .all-comments {
    img {
      height: 32px;
      width: 32px;
      border-radius: 20px;
    }

    .all-details {
      color: #5d5962;
      font-family: AvenirArabic-Medium;
      font-size: 14px;

      span {
        color: #1b0730;
        font-family: AvenirArabic-Heavy;
      }

      .report-number {
        margin-left: 4px;
        min-width: 80px;
        font-family: AvenirArabic-Heavy;
        color: #9244eb;
        font-size: 12px;
        line-height: 20px;
        text-decoration-line: underline;
        text-align: right;

        svg {
          margin-right: 2px;
        }
      }
    }
  }

  .view-all-comments {
    border-top: 1px solid rgba(121, 123, 161, 20%);
    border-bottom: 1px solid rgba(121, 123, 161, 20%);
    padding: 10px 0;
    font-family: AvenirArabic-Heavy;
    color: #9244eb;
    font-size: 16px;
    line-height: 24px;
    text-decoration-line: underline;
    text-align: center;
  }
}

.stream-epicode-tab {
  border: 1px solid #797ba1;
  padding: 15px;
  border-radius: 18px;

  .nav-pills {
    border-radius: 0;
    border: 0;
    padding: 0;

    .nav-item {
      .nav-link {
        color: #5d5962;
        font-size: 16px;
        background: #eeefff;
        border-radius: 12px;
        margin: 0 6px;
        border-bottom: 4px solid transparent;

        &.active {
          border-radius: 0px !important;
          border-bottom: 4px solid #eeefff;
          background: transparent;
        }
      }
    }
  }

  .tab-content {
    padding: 0px 9px 9px 9px;

    p {
      color: #5d5962;
      font-size: 16px;
      line-height: 24px;
    }
  }
}

.podcasts-list {
  @media (max-width: 767px) {
    //grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
    //grid-gap: 16px;
    .position-absolute {
      .big-text {
        font-size: 14px !important;
      }
    }
  }
  display: grid;
  gap: 12px;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));

  .position-relative {
    &:hover {
      .position-absolute {
        opacity: 1;
      }
    }

    .position-absolute {
      .big-text {
        font-size: 26px;
      }

      //border-radius: 50%;
      //opacity: 0;
      //background: rgba(0, 0, 0, 50%);
      //top: 50%;
      //left: 50%;
      //transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      color: #fff;
      transition: all 0.3s ease-in;
      font-size: 16px;
      letter-spacing: 0.32px;
      cursor: pointer;
    }
  }
}

.favorites-slider {
  .search-bar {
    > div {
      margin-bottom: 0 !important;
    }
  }

  .podcast-frame {
    max-width: 220px;
    padding: 10px;
    border-radius: 18px;

    .podcast-frame-image-box {
      img {
        max-width: 100%;
        height: auto;
        border-radius: 24px;
      }
    }

    .podcast-frame-title-description {
      padding: 10px 0 0 0;

      h4 {
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 2px;
      }

      p {
        font-size: 10px;
        line-height: 14px;
        margin-bottom: 4px;
      }
    }
  }
}

.get-ads-free {
  background: #e2f952 url('../svg/subscription/BG1.svg') center center / cover;
  background-repeat: no-repeat;
  padding: 20px 50px;
  min-height: 150px;
  border-radius: 30px;
  @media (max-width: 767px) {
    padding: 24px 10px 10px !important;
    h1 {
      font-size: 20px !important;
      margin-bottom: 10px !important;
    }
    .social-media-btn {
      padding: 6px 21px !important;
      font-size: 10px !important;
      border-radius: 5px !important;
      margin-top: 6px !important;
    }
    .ads-free-block {
      div {
        &.border-bg {
          height: 56px !important;
        }
      }
    }
  }

  h1 {
    color: #9244eb;
    font-size: 38px;
    margin: 0;
    @media (max-width: 1100px) {
      font-size: 32px;
    }
  }

  .ads-free-block {
    grid-gap: 16px;
    color: #434243;
    font-size: 18px;
    font-family: AvenirArabic-Heavy;
    @media (max-width: 767px) {
      font-size: 12px;
      line-height: normal;
    }

    div {
      &.border-bg {
        background-image: url('../svg/subscription/BG2.svg');
        width: 2px;
        height: 88px;
        background-position: center center;
      }

      &.social-media-btn {
        max-width: unset !important;
      }
    }
  }

  .social-media-btn {
    max-width: unset !important;
    margin: unset !important;
    color: #1b0730;
    font-size: 18px;
    font-family: AvenirArabic-Heavy;
    padding: 13px 30px;
    cursor: pointer;
  }
}

.monthly-yearly {
  border: 1px solid #797ba1;
  padding: 6px !important;
  border-radius: 16px;

  button {
    width: 100%;
    background: transparent !important;

    &.active {
      background: linear-gradient(
        90deg,
        #9445ee -0.01%,
        #8d43e3 14.99%,
        #7b3ec7 40.99%,
        #5f3799 75%,
        #483274 100%
      ) !important;
      color: #ffffff;
      border-radius: 12px;
    }

    padding: 10px 40px;
    color: #5d5962;
    text-align: center;
    font-family: AvenirArabic-Heavy;
    font-size: 18px;
    width: 131px;
    height: 50px;
    flex-shrink: 0;
  }
}

ul,
ol {
  list-style: none !important;
  padding-left: 0;
  margin: 0;
}
