// @font-face {
//     font-family: AvenirArabic-Light;
//     src: url(../assets/fonts/AvenirArabic-Light.otf);
// }

// @font-face {
//     font-family: AvenirArabic-Medium;
//     src: url(../assets/fonts/AvenirArabic-Medium.otf);
// }

// @font-face {
//     font-family: AvenirArabic-Book;
//     src: url(../assets/fonts/AvenirArabic-Book.otf);
// }

// @font-face {
//     font-family: AvenirArabic-Heavy;
//     src: url(../assets/fonts/AvenirArabic-Heavy.otf);
// }
html {
  scroll-behavior: smooth;
}

html,
body {
  min-height: 100%;
  //scrollbar-color: #9244eb;
}

::-webkit-scrollbar-track {
  border-radius: 3px;
  background-color: rgba(121, 123, 161, 20%);
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  border-radius: 3px;
  background-color: rgba(121, 123, 161, 20%);
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #9244eb;
}

.body-layout {
  .container,
  .mt-87 {
    margin-top: 74px;
  }
}

nav {
  &.sticky-top {
    position: fixed;
    width: 100%;
  }
}

.mb-70 {
  margin-bottom: 70px !important;
}

.navbar-nav .dropdown > .dropdown-menu {
  background: #ffffff;
  box-shadow: 2px 2px 16px rgba(72, 50, 116, 0.2);
  border-radius: 10px;
  border: 0;
  padding: 10px;
  width: max-content;
}

@media (min-width: 992px) {
  .navbar-nav .dropdown > .dropdown-menu::after {
    content: none;
    position: absolute !important;
    right: 13px;
    top: -22px;
    transform: rotate(270deg);
  }
}

.navbar-nav .dropdown > .dropdown-menu > li > a {
  background: transparent;
  padding: 14px 10px;
  color: #1b0730;
}

.navbar-nav .dropdown > .dropdown-menu > li:hover {
  background-color: #e9eaff !important;
  // border-radius: 10px;
}

.navbar-nav .dropdown > .dropdown-menu > li:first-child:hover {
  border-radius: 10px 10px 0 0;
}

.navbar-nav .dropdown > .dropdown-menu > li:last-child:hover {
  border-radius: 0 0 10px 10px;
}

.navbar-nav .dropdown > .dropdown-menu > li:not(:last-child) {
  border-bottom: 1px solid rgb(121, 123, 161, 24%);
}

nav .dropdown .nav-link {
  color: #ffffff;
  font-size: 18px;
  line-height: 28px;
}

nav .dropdown .dropdown-item:hover,
nav .dropdown .dropdown-item:active {
  background-color: transparent !important;
  color: #1b0730 !important;
}

.main-div {
  padding: 10px 0 30px;

  & > div {
    flex-wrap: wrap;
  }
}

.main-title {
  .font-36 {
    font-size: 36px;
  }

  font-size: 32px;
  line-height: 50px;
  font-weight: 800;
  color: var(--color-white);
}

.user-table {
  overflow: auto;

  th {
    text-transform: uppercase !important;
  }

  tr {
    &:hover {
      background: rgba(0, 0, 0, 0.05) !important;
    }
  }
}

.user-table tr > th {
  line-height: 24px;
  background: transparent;
  border: 0;
  white-space: nowrap;

  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  color: var(--color-body);
  padding: 12px;
  vertical-align: bottom;

  font-size: 14px;
  font-weight: 700;

  &#Episodes,
  &#Ratings,
  &#Views {
    text-align: center;
  }
}

.user-table tr {
  > td {
    padding: 10px 12px;
    font-size: 1.6rem;
    line-height: 24px;
    color: var(--color-white);
    border: 0;
    vertical-align: middle;
    border-bottom: 1px solid var(--color-primary-opacity);
    align-content: center;
    //height: 60px;
    @media screen and (max-width: 1440px) {
      white-space: nowrap;
    }

    .text-truncate {
      color: #9244eb !important;
    }
  }
}

.user-table tr > td > span {
  padding: 4px 19px 4px 30px;
  border-radius: var(--radius-full);
  font-size: 14px;
  line-height: 20px;
  color: var(--color-white);
  display: inline-block;
  position: relative;
  align-content: center;
}

.user-table tr > td > span::before {
  content: '';
  width: 6px;
  height: 6px;
  background: var(--color-white);
  position: absolute;
  border-radius: 50%;
  left: 16px;
  top: 50%;
  transform: translate(-50%, -50%);
}

.user-table tr > td > .dropdown > button,
.user-table tr > td > .dropdown > button:active {
  padding: 0;
  background: transparent;
  border: 0;
}

.user-table tr > td > .dropdown > .dropdown-menu {
  background: var(--color-dark-1);
  box-shadow: 2px 2px 16px rgba(72, 50, 116, 0.2);
  border-radius: 10px;
  border: 0;
  padding: 10px;
}

// .user-table tr > td > .dropdown > .dropdown-menu::after {
//   content: var(--dorp-right-arrow);
//   position: absolute;
//   position: absolute;
//   right: -17px;
//   bottom: 30px;
// }

.user-table tr > td > .dropdown > .dropdown-menu > li > div {
  background: transparent;
  padding: 14px 10px;
  color: #1b0730;
  cursor: pointer;
}

td.column-wrapper.episodes,
td.column-wrapper.views,
td.column-wrapper.ratings {
  text-align: center;
}

.user-table tr > td > .dropdown > .dropdown-menu > li {
  padding: 5px;
}

.user-table tr > td > .dropdown > .dropdown-menu > li:hover {
  background-color: #e9eaff !important;
  padding: 5px;
}

.user-table tr > td > .dropdown > .dropdown-menu > li:first-child:hover {
  border-radius: 10px 10px 0 0;
}

.user-table tr > td > .dropdown > .dropdown-menu > li span {
  margin-left: 12px;
}

.user-table tr > td > .dropdown > .dropdown-menu > li:last-child:hover {
  border-radius: 0 0 10px 10px;
}

.user-table tr > td > .dropdown > .dropdown-menu > li:not(:last-child) {
  border-bottom: 1px solid rgb(121, 123, 161, 24%);
}

.user-table tr > td button.dropdown-toggle::after {
  content: none;
}

.blocked,
.failed {
  background: #f02323 !important;
  color: #ffffff !important;
}

.blocked::before,
.failed::before {
  background: #ffffff !important;
}

.table-border-top {
  padding: 18px 0;
}

.search-bar .input-group {
  width: auto;
  margin-right: 16px;
  position: relative;
}

.search-bar .input-group::before {
  content: var(--search-icon);
  position: absolute;
  left: 27px;
  top: 53%;
  transform: translate(-50%, -50%);
  z-index: 9;
}

.search-bar .input-group input {
  border: 1px solid #797ba1 !important;
  border-radius: 12px;
  padding: 15px 15px 15px 50px;
  height: 54px;
  max-width: 280px;
}

.btn-action::before {
  cursor: pointer !important;
  border: 0 !important;
}

.enhance-btn .btn-style {
  margin: 0 auto;
}

.number-of-data {
  p {
    font-weight: 400;
    font-size: 14px;
  }
}

.pagination li {
  font-weight: 800;
  font-size: 14px;
  color: var(--color-white);
  line-height: 24px;
  cursor: pointer;
}

.pagination li.disabled {
  color: #797ba1;
  font-weight: 500;
}

.pagination li:last-child {
  font-weight: 500;
  font-size: 16px;
}

.pagination li:first-child {
  font-weight: 500;
  font-size: 16px;
}

.pagination li.number {
  font-weight: 800;
  margin: 0 10px;
  color: var(--color-white);
}

.pagination li.number.active {
  text-align: center;
  background-color: var(--color-white);
  width: 30px;
  height: 30px;
  line-height: 0;
  border-radius: var(--radius-small);
  color: var(--color-primary);
  padding: 5px;
}

.toast_msg {
  background: rgba(253, 96, 96, 0.24);
  width: 100%;
  padding: 15px 20px;
  border-radius: var(--radius);
  margin: 0 auto;
  color: var(--color-white);
  max-width: 500px;

  img {
    width: 30px;
  }
}

.toast-main {
  margin: 0 !important;
  border-radius: var(--radius);
}

// @media (max-width: 1700px) {
//   .user-table tr > td > .dropdown > .dropdown-menu {
//     transform: translate(-70px, 0px) !important;
//   }
// }

// @media (max-width: 1000px) {
//   .user-table tr > td > .dropdown > .dropdown-menu {
//     transform: translate(-60px, 0px) !important;
//   }
// }

@media (min-width: 992px) {
  .container-fluid {
    padding: 0 70px;
  }
}

@media (max-width: 991px) {
  .main-title {
    font-size: 24px !important;
    line-height: normal;
    word-break: break-word;
  }

  .main-div {
    padding: 10px 0 30px;
  }
  .search-bar > div {
    width: 100% !important;
    margin-bottom: 20px;
    text-align: center;
  }
  .search-bar .input-group input {
    max-width: unset;
  }
  .btn-width {
    .btn-style {
      width: 100%;
    }
  }
}

@media (max-width: 767px) {
  .main-div {
    padding: 10px 0 !important;
  }
  .table-bottom-bar > div {
    width: 100% !important;
    margin-bottom: 30px;
    text-align: center;
  }
  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0 !important;
  }
  .navbar-nav .dropdown > .dropdown-menu::after {
    content: none;
  }
}
