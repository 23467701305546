//@import "bootstrap/dist/css/bootstrap.min.css";
@import 'react-image-crop/src/ReactCrop.scss';
@import './custom.scss';
@import '~bootstrap/scss/bootstrap.scss';
@import './login.scss';
@import './home.scss';
@import './modal.scss';
@import './loader.scss';
@import './subscription.scss';
@import './utility';
@import './wave.scss';

@font-face {
  font-family: AvenirArabic-Light;
  src: url(../fonts/AvenirArabic-Light.otf);
}

@font-face {
  font-family: AvenirArabic-Medium;
  src: url(../fonts/AvenirArabic-Medium.otf);
}

@font-face {
  font-family: AvenirArabic-Book;
  src: url(../fonts/AvenirArabic-Book.otf);
}

@font-face {
  font-family: AvenirArabic-Heavy;
  src: url(../fonts/AvenirArabic-Heavy.otf);
}

.container {
  max-width: 1320px !important;
}

.mt-110 {
  margin: 70px 0 27px;
}

.mt-110-2 {
  margin: 110px 0 30px;
}

.mt-60 {
  margin: 60px 0 86px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-54 {
  margin-bottom: 30px;
}

.f-500 {
  font-weight: 500;
}

.color-black {
  color: #000000;
}

.container-fluid {
  padding-bottom: 30px;
}

html,
body {
  min-height: 100%;
}
body {
  @media (min-width: 1198px) and (max-width: 1294px) {
    zoom: 0.9;
  }
}

.max-width-250 {
  max-width: 250px !important;
}

.m-left {
  margin: 0 0 0 40px !important;
}

.h-100v {
  height: 100vh;
}

.h-100p {
  height: 100px;
}

.bottom-pattern {
  position: relative;
  min-height: calc(100vh - 86px);
  // padding-bottom: 100px;
}

.btn-bg,
.btn-nav,
.btn-bg:active,
.btn-bg:focus {
  background: unset !important;
  background-color: #9244eb !important;
}

.password {
  padding-right: 40px !important;
  background-color: #1b0730;
}

.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: unset;
  right: 0;
  margin-top: var(--bs-dropdown-spacer);
}

.side-popup {
  position: absolute;
  inset: 0 0 auto auto;
  right: 0 !important;
  transform: translate(-171px, 0px);
}

.invite-btn {
  padding-right: 10px;
}

.admin-name {
  margin-block: auto;
}

.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative;
}

.editor-actions:hover,
.btn-bg:hover,
.btn-style:hover {
  color: white !important;
  //background: linear-gradient(
  //  90deg,
  //  #9445ee 0%,
  //  #8d43e3 15%,
  //  #7b3ec7 41%,
  //  #5f3799 75%,
  //  #483274 100%
  //) !important;
}

.btn-nav:hover {
  color: white !important;
  background: linear-gradient(90deg, #9445ee -0.01%, #8d43e3 14.99%, #7b3ec7 50.99%) !important;
}

.btn-style {
  background: #9244eb;
  border-radius: 12px;
  //height: 54px;
  font-size: 18px;
  //line-height: 50px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  padding: 10px 32px;
  &.logout {
    border: 1px solid #797ba1 !important;
    background: #fff !important;
    color: #1b0730 !important;
    z-index: 999;
  }
}

.forward-btn {
  svg {
    height: 26px;
  }

  @media (max-width: 991px) {
    margin-right: 0.8rem !important;

    svg {
      height: 22px;
    }
  }

  display: flex;
  align-items: center;
  border-radius: 10px;
  background: transparent;
  margin: 4px 0;
  margin-right: 20px !important;
}

.h-300 {
  height: 300px !important;
}

.profile-image {
  height: 55px;
  width: 55px;
}

.forward-btn:hover {
  cursor: pointer;
}

.cursor-pointer {
  cursor: pointer;
}

.reject-btn {
  color: #1b0730 !important;
  background-color: transparent !important;
  border: 1px solid #797ba1;
  border-radius: 12px;
  height: 54px;
  font-size: 18px;
  line-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.delete_toast {
  background: #5e0030 !important;
  border: 1px solid var(--color-secondary) !important;

  &.toast-msg-light {
    background: #ff0f7bbf !important;
  }
}

.success_toast {
  background: #260836 !important;
  border: 2px solid var(--color-primary-3) !important;

  color: var(--color-white);
  &.toast-msg-light {
    background: #5a3690bf !important;
  }
}

.reject-btn:hover,
.reject-btn:active {
  background-color: #e9eaff !important;
  border: 1px solid #797ba1 !important;
}

.bottom-pattern {
  // background: var(--home-bottom);
  //background: url("../images/user-layout-bottom.png");
  background-position: bottom !important;
  background-repeat: repeat-x !important;
}

.toast-style {
  background: transparent !important;
  box-shadow: none !important;
}

.h-42px {
  height: 42px;
}

.error {
  top: -10px;
  font-size: 12px !important;
}

.form-control:focus {
  box-shadow: none !important;
}

.home-button {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;

  svg {
    margin-right: 0 !important;
  }
}

.crop-image-modal-header {
  div {
    margin-left: 25px;
  }

  .btn-close {
    margin-top: 0;
  }
}

.close-podcast {
  //position: absolute !important;
  //top: -20px;
  //right: -12px;
  //z-index: 10;
  //cursor: pointer;
  //height: 40px;
  //width: 40px;
  //border-radius: 50%;
}

.close-banner {
  //position: absolute !important;
  right: -33px;
  top: -22px;
  //cursor: pointer;
  //height: 40px;
  //width: 40px;
  //border-radius: 50%;
  //z-index: 10;
}

.theme-color {
  color: #9244eb;
}

.theme-background {
  background: #9244eb;
}

@media (max-width: 991px) {
  .m-left {
    margin: 0;
  }
}

.mobile-menu-container {
  // background-color: #9244eb;
  .mobile-menu-item {
    padding: 10px 10px;
    border-top: 1px solid white;
    color: #ffffff;

    a {
      width: 100%;
    }
  }
}

button:disabled {
  cursor: not-allowed;
}

.top-50 {
  transform: translateY(-50%);
}

.color-input {
  background: #d9daf7 !important;
  border: #9244eb !important;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}

.pos-t-26 {
  top: -16px !important;
}

.invite-icon-mobile {
  margin-left: 8px;
}

.invite-text-mobile {
  margin-left: 18px;
}

.loader-small {
  top: -6px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-30 {
  margin-top: 30px;
}

.link {
  color: var(--color-primary-3);

  &:hover {
    color: var(--color-primary-3);
  }
}

.link-text {
  text-decoration: none;
}

.payment-element-container {
  display: flex !important;

  .__PrivateStripeElement {
    iframe {
      width: 500px !important;
      max-width: 100%;
      //@media (max-width: "525px") {
      //  width: 300px;
      //}
    }
  }
}

.calendar-container {
  .from-group-wrapper {
    position: relative;
  }

  input[type='date'],
  input[type='time'] {
    position: relative;
    overflow: hidden;
  }

  input[type='date'],
  input[type='time'] {
    &::-webkit-calendar-picker-indicator {
      color: transparent;
      background: none;
      z-index: 1;
      display: block;
      top: 0;
      left: 0;
      position: absolute;
      transform: scale(35);
    }
  }

  .calendar-icon {
    position: absolute;
    top: 48px;
    right: 13px;
  }
}

select.form-control {
  background-image: url('../svg/subscription/FillArrowDown.svg') !important;
  background-position: right 1.4rem center !important;
  background-repeat: no-repeat !important;
  background-size: 1em 1em !important;
}

.create-profile,
.edit-profile-w {
  .from-group-wrapper {
    label:not(.radio-btn label) {
      font-size: 16px;
    }
  }

  .from-group-wrapper {
    position: relative;

    .form-select {
      margin-bottom: 0;
    }

    .select-suggestion {
      color: #5d5962;
    }

    &.empty-value {
      input {
        color: #797ba1 !important;
      }
    }
  }

  .gender-wrapper {
    margin-bottom: 0;

    .from-group-wrapper > label {
      margin-bottom: 8px;
    }
  }

  .form-control {
    color: #1b0730;
  }

  .profile-pic-wrapper {
    //max-width: 307px;
    //margin: 0 auto;
    margin-bottom: 20px;
    position: relative;

    .profile-image-wrapper {
      max-width: 190px;

      img {
        border-radius: 10px;
      }
    }
    label {
      font-size: 16px;
      margin-bottom: 0.5rem;
    }

    .text-danger {
      top: unset;
      bottom: -20px;
    }
  }

  //input[type="date"]:invalid::-webkit-datetime-edit {
  //  color: #797ba1 !important;
  //}
}

.create-profile-form {
  max-width: 500px;
  margin: auto;
}

#infinite-scrollbar {
  .infinite-scroll-component {
    overflow: hidden !important;
  }
}

.podcast-item-list-container {
  margin: auto;

  //@media (max-width: 1420px) {
  //  width: 80%;
  //}
  //
  //@media (max-width: 1225px) {
  //  width: 85%;
  //}
}

.form-field-label {
  font-size: 16px;
  line-height: 24px;
  color: #1b0730;
  margin-bottom: 8px;
}

// Drop zone
.drop-zone {
  max-width: unset;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 25px;
  text-align: center;
  font-weight: 500;
  font-size: 20px;
  cursor: pointer;
  color: var(--color-white);
  //border: 1px dashed var(--color-primary-3);
  //border-radius: 14px;
  //max-width: 800px;
  //margin:0 auto 30px;

  span {
    line-height: 22px;
    margin-bottom: 10px;
  }

  a {
    color: #5d5962;
    text-decoration-line: underline;
  }
}

.drop-zone__input {
  display: none;
}

.form-field-label {
  font-size: 16px;
  line-height: 24px;
  color: #1b0730;
  margin-bottom: 8px;
}

.search-result {
  display: grid;
  gap: 12px;
  grid-template-columns: repeat(
    auto-fill,
    minmax(300px, 1fr)
  ); /* Ensures consistent column sizing */
  justify-content: center;

  .search-item {
    display: flex;
    flex-direction: column; /* Ensure the content is stacked vertically */
    justify-content: space-between; /* Space between elements inside the item */
    align-items: center; /* Center content horizontally */
    padding: 16px; /* Add consistent padding */
    box-sizing: border-box;
    background: #ffffff; /* Optional: Add a background color for uniformity */
    border-radius: 8px; /* Optional: Add border-radius for better visuals */
    min-height: 200px; /* Ensure a consistent height */
    max-width: 100%; /* Prevent overflowing */
    transition: box-shadow 0.3s ease;

    &:hover {
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Optional: Add hover effect */
    }

    img {
      max-width: 100%; /* Ensure images scale within the item */
      height: auto; /* Maintain aspect ratio */
      border-radius: 8px; /* Optional: Match card border radius */
    }

    .podcast-title-description {
      text-align: center; /* Center the text content */
      margin-top: 12px; /* Add spacing */
    }
  }
}

.uniform-result {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 16px; /* gap of 16px between items, adjust as needed */
}

.uniform-result > .search-item {
  flex: 1 1 33.3333%; /* Flex item that takes up 1/3 of the container width */
  margin: unset !important;
  box-sizing: border-box; /* Ensures padding and margin do not affect width */

  @media (max-width: 1156px) {
    flex: 1 1 45%; /* At smaller widths, items will take up 45% of the width */
  }

  @media (max-width: 768px) {
    flex: 1 1 100%; /* On very small screens, items will take up the full width */
  }

  .podcast-frame-image-box {
    img {
      width: 100%;
      height: auto;
      border-radius: 10px !important;
    }
  }
}

.podcast-frame {
  background-color: var(--color-primary);
  border-radius: var(--radius);
  padding: 14px;
  max-width: 305px;
  width: 100%;
  margin: 0 auto;
  gap: 20px;
  justify-items: center;

  @media (max-width: 767px) {
    padding: 10px !important;
    gap: 12px !important;

    .podcast-frame-title-description {
      padding: 10px 0 2px !important;

      .text-uppercase {
        margin-top: 0 !important;
        font-size: 10px !important;
      }

      h4 {
        font-size: 14px !important;
        margin-bottom: 0 !important;
      }

      p {
        font-size: 12px !important;
        margin-bottom: 2px !important;
        font-family: AvenirArabic-Medium !important;
        font-weight: 400;
      }

      .btn-style {
        padding: 5px 8px !important;
        font-size: 12px !important;
      }

      .mt-3 {
        margin-top: 10px !important;
      }
    }

    border-radius: 12px !important;
    padding: 8px;

    img {
      border-radius: 10px !important;
    }

    .subscribed-podcaster-img {
      border-radius: 50% !important;
    }
  }

  .podcast-frame-image-box {
    max-width: 373px;
    max-height: 286px;
    min-height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    flex-direction: column;
    padding: 20px;
    gap: 15px;

    .user-image {
      //object-fit: fill;
      //height: auto;
      //max-width: 100%;
      //border-radius: 24px;
      //aspect-ratio: 1.1/1;
      width: 150px;
      height: 150px;
      background: var(--color-primary);
      border-radius: 50%;
      border: 5px solid var(--color-border);
      background: var(--color-bg-2);

      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 50px;
      }
      .img-fluid {
        width: fit-content;
        border-radius: 50%;
      }
    }
    .user-name p {
      font-size: 18px;
      color: var(--color-white) !important;
      font-weight: 500;
      text-transform: capitalize;
    }
  }

  .podcast-frame-title-description {
    padding-top: 16px;

    .text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-family: AvenirArabic-Heavy;
    }

    h4 {
      font-size: 20px;
    }

    p {
      color: #5d5962;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 0;
    }

    .hyperlink {
      color: #ffffff;
      font-size: 14px;
      text-decoration-line: underline;
      font-family: AvenirArabic-Heavy;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
      max-width: 100%;
    }

    .hyperlink-black {
      color: #000000;
    }

    .hyperlink:hover {
      opacity: 0.8;
    }
  }

  .btn-style {
    color: #fff;
    text-align: center;
    font-size: 12px;
    line-height: normal;
    padding: 8px 20px;
    height: auto;
    border-radius: 8px;
  }

  .min-left {
    color: #797ba1;
    text-align: right;
    font-size: 12px;
    line-height: 20px;
  }
}

.verify-banner {
  display: flex;
  align-items: center;
  justify-content: center;
  position: sticky;
  top: 0;
  padding: 8px;
  gap: 8px;
  p.banner-verify-text,
  button {
    margin: 0;
    color: var(--color-white) !important;
  }
  button {
    font-weight: 500;
    text-decoration: underline;
    padding: 0;
  }
  img {
    width: 20px;
  }
  &.is-light-banner {
    background: var(--color-secondary);
  }
  background: #5e0030;
  border-bottom: 1px solid #ff0f7b;
}
// podcast / episode status
.published {
  background: #e2f952;

  &::before {
    background: #9244eb;
  }
}

.unpublished {
  background: #797ba1;
  color: #797ba1;

  &::before {
    background: #797ba1;
  }

  span {
    margin-right: 10px;
  }
}

.draft,
.Status .disabled,
.status .deleted {
  background: #ff800c;
  color: #ffffff;

  &::before {
    background: #ffffff;
  }

  span {
    margin-right: 10px;
  }
}

.scheduled {
  background: #00be4c;
  color: #ffffff;

  &::before {
    background: #ffffff;
  }

  span {
    margin-right: 10px;
  }
}

.status-block {
  background: #e2f952;
  border-radius: 10px;
  color: #1b0730;
  display: inline-block;
  font-family: AvenirArabic-Heavy;
  line-height: 24px;
  margin-right: 12px;
  padding: 7px 10px;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  .published {
    span {
      color: #9244eb;
      font-size: 20px;
    }
  }
}

.status-block-draft {
  background: #ff800c !important;
}

.status-block-published {
  background: #e2f952 !important;
}

.status-block-unpublished {
  background: #797ba1 !important;
}

.status-block-scheduled {
  background: #00be4c !important;
}

.status-block-blocked {
  background: #f02323 !important;
}

.play-fixed-box {
  z-index: 3;
  background: var(--color-primary-3);

  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
}

.close-audioPlayer {
  position: absolute;
  top: 12px;
  right: 21px;
  cursor: pointer;

  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: rgba($color: #fff, $alpha: 0.24);
    border-radius: 50%;
  }

  .icon-btn {
    position: relative;

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

@media only screen and (max-width: 365px) {
  .close-audioPlayer {
    top: 2px;
  }
}

.drag-active {
  border: 2px dashed #9244eb !important;
}

.flag-red {
  svg path:first-child {
    fill: red;
  }
}

.warning-modal {
  &.modal-wrapper .modal-content .modal-body {
    text-align: center;
    padding: 40px !important;

    .enhance-btn {
      margin: 25px auto 0px auto;
    }

    p {
      max-width: none !important;
    }
  }
}

.promocode-uppercase {
  input {
    text-transform: uppercase;

    &::placeholder {
      text-transform: none;
    }
  }
}

.play-pause-btn {
  padding: 0 !important;

  svg {
    width: 50px;
    height: 50px;
  }
}

.rhap_main-controls-button {
  width: unset;
  height: unset;
}

.bowser-comptability-text {
  font-size: 20px !important;
}

.not-found {
  height: calc(100vh - 146px);
  @media (max-width: 991px) {
    height: calc(100vh - 114px);
  }

  h1 {
    font-size: 60px !important;
    color: var(--color-secondary);
  }

  img {
    width: 100%;
    max-width: 300px;
    height: fit-content;
  }

  .not-found-title {
    font-size: 36px;
    font-weight: 800;
    line-height: 30px;
    letter-spacing: 0;
    text-align: center;
    color: var(--color-primary-3);
    @media (max-width: 550px) {
      font-size: 28px;
    }
  }

  .no-network-title {
    font-size: 36px;
    font-weight: 800;
    line-height: 30px;
    letter-spacing: 0;
    text-align: center;
    margin-bottom: 10px;
    color: var(--color-secondary);
    @media (max-width: 550px) {
      font-size: 28px;
    }
  }

  .no-network-btn {
    background: #d9d9d9;
    color: var(--color-secondary);
    font-weight: 600;
  }

  .no-network-img {
    width: unset;
  }

  .not-found-description {
    font-size: 24px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: center;
    @media (max-width: 550px) {
      font-size: 18px;
      width: 80%;
    }
  }

  .back-to-home {
    width: (335px);
    height: (54px);
    //padding: 18 114 18 114;
    gap: 10px;
    border-radius: 12px;
  }
}

.slug-url {
  input {
    padding-right: 35px !important;
  }
}

.horizontal-ads {
  .ad-image-label {
    right: 8px;
  }
}

.ad-image-label {
  position: absolute;
  right: 0px;
  top: 18px;
  background: #ffffff4a;
  border-radius: 17px 0 0 17px;
  width: 40px;
  text-align: center;
}

::-moz-selection {
  color: var(--color-white);
  background: var(--color-primary-2);
}

::selection {
  color: var(--color-white);
  background: var(--color-primary-2);
}

ul {
  list-style: none;
  padding: 0 !important;
  margin: 0 !important;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

/**************************************
 CSS Index
****************************

/--- Default Styles -----/

01.Variables
02.Mixins
03.Reset
04.Typography
05.Extend
06.Animations
07.Shortcode
08.Spacing
09.Common
10.Forms
11.Sal
12.Text Animation
13.Themebg

/--- Header Styles -----/

01.Headerdefault
02.Nav
03.Header Top
04.HeaderTopBar
05.Mobilemenu

/--- Elements -----/

01.Breadcrumb
02.Sectiontitle
03.Button
04.Features
05.Card
06.Counter
07.Progressbar
08.Accordion
09.Social Share
10.Team
11.Portfolio
12.Testimonial
13.Slickslide
14.HowItWork
15.Call to Action
16.Tab
17.Pricing
18.Split
19.Vedio
20.Gallery
21.Contact
22.Brand
23.404
24.Advance Tab
25.Advancepricing
26.Contact Form
27.Swicher
28.Backto Top
29.About
30.Newsletter

/--- Blog -----/
01.Blog
02.Sidebar
03.Blog Details

/--- Footer -----/
01.Footer
02.Copyright

/--- Template -----/
01.Banner
02.Portfoliodetails
03.Splash
04.Lightmode

***************************************/

/**************************************
    Default Styles
***************************************/
@import 'default/variables';
@import 'default/mixins';
@import 'default/reset';
@import 'default/typography';
@import 'default/extend';
@import 'default/animations';
@import 'default/shortcode';
@import 'default/spacing';
@import 'default/common';
@import 'default/forms';
@import 'default/sal';
@import 'default/text-animation';

/**************************************
    Theme Styles
***************************************/
@import 'color/themebg';

/**************************************
    Header Styles
***************************************/
@import 'header/headerdefault';
@import 'header/nav';
@import 'header/header-top';
@import 'header/headerTopBar';
@import 'header/mobilemenu';
@import 'header/onepage';

/**************************************
            Elements
***************************************/
@import 'elements/breadcrumb';
@import 'elements/sectiontitle';
@import 'elements/button';
@import 'elements/service';
@import 'elements/card';
@import 'elements/counter';
@import 'elements/progressbar';
@import 'elements/accordion';
@import 'elements/social-share';
@import 'elements/team';
@import 'elements/portfolio';
@import 'elements/testimonial';
@import 'elements/slickslide';
@import 'elements/timeline';
@import 'elements/call-to-action';
@import 'elements/tab';
@import 'elements/pricing';
@import 'elements/split';
@import 'elements/vedio';
@import 'elements/gallery';
@import 'elements/contact';
@import 'elements/brand';
@import 'elements/404';
@import 'elements/advance-tab';
@import 'elements/advancepricing';
@import 'elements/contact-form';
@import 'elements/swicher';
@import 'elements/backto-top';
@import 'elements/about';
@import 'elements/newsletter';
@import 'elements/feature';
@import 'elements/signup';
@import 'elements/release-notes';
@import 'elements/utilize';

/**************************************
            Blog
***************************************/

@import 'blog/blog';
@import 'blog/sidebar';
@import 'blog/blog-details';

/**************************************
    Footer Styles
***************************************/
@import 'footer/footer';
@import 'footer/copyright';

/**************************************
            Template
***************************************/
@import 'template/banner';
@import 'template/portfoliodetails';
@import 'template/splash';
@import 'template/lightmode';
@import 'template/application';
@import 'template/collaborate';

/**************************************
            Dashboard
***************************************/
@import 'dashboard/dashboard';
@import 'dashboard/modal-box';
@import 'dashboard/settings';
/**************************************
            Pages
***************************************/
@import 'privacyPolicy/privacyPolicy';
@import 'TermAndConditions/term-and-conditions';
