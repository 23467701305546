.m-t-n6 {
  margin-top: -6px;
}
.m-t-n3 {
  margin-top: -3px;
}
.m-l-10 {
  margin-left: 10px;
}
.w-180 {
  width: 180px !important;
}
.h-180 {
  height: 180px !important;
}

.m-b-180 {
  margin-bottom: 180px;
}

.m-t-24 {
  margin-top: 24px;
}

.m-r-2 {
  margin-right: 2px;
}

.m-r-10 {
  margin-right: 10px;
}

.m-r-12 {
  margin-right: 12px;
}

.m-r-5 {
  margin-right: 5px;
}

.m-r-16 {
  margin-right: 16px;
}

.p-b-100 {
  padding-bottom: 100px;
}

.m-b-5 {
  margin-bottom: 5px;
}
.m-b-20 {
  margin-bottom: 20px;
}
.h-100 {
  height: 100%;
}

.h-189 {
  height: 189px;
}

.m-l-5 {
  margin-left: 5px;
}
.m-15 {
  margin: 15px;
}

.line-height-30 {
  line-height: 30px;
}
.fs-36 {
  font-size: 36px;
}

.m-t-0-i {
  margin-top: 0px !important;
}

.m-b-25 {
  margin-bottom: 25px;
}

.m-b-15 {
  margin-bottom: 15px;
}

.w-100px {
  width: 100px;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

.cursor-default {
  cursor: default !important;
}

.text-break-all {
  word-break: break-all;
}

.event-disabled {
  pointer-events: none;
}

.p-14 {
  padding: 14px !important;
}

.p-15 {
  padding: 15px;
}

.red {
  color: red;
}
.flex-none {
  flex: none;
}

.flex-1 {
  flex: 1;
}

.overflow-ellipsis {
  display: -webkit-box !important;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
}

.line-clamp-2 {
  -webkit-line-clamp: 2;
}

.white-space-nowrap {
  white-space: nowrap !important;
}

.z-100 {
  z-index: 100;
}
.break-word {
  word-break: break-word;
}
