.contact-page {
  h2 {
    color: #9244eb !important;
    font-family: AvenirArabic-Heavy;
  }
  p.information,
  li {
    color: #1b0730;
    font-family: AvenirArabic-Medium;
  }

  .pointers {
    font-family: AvenirArabic-Medium;
    font-weight: 800;
  }
  footer {
    height: 70px;
    background: linear-gradient(
      90deg,
      #9445ee -0.01%,
      #8d43e3 14.99%,
      #7b3ec7 40.99%,
      #5f3799 75%,
      #483274 100%
    );
    width: 100%;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    position: absolute;
    bottom: 0;
    div {
      color: #fff;
      text-decoration: none;
      position: relative;
      font-family: AvenirArabic-Heavy;
    }
    ul {
      display: flex;
      align-items: center;
      gap: 20px;
      margin-bottom: 0;
      li {
        list-style: none;
        a {
          color: #fff;
          text-decoration: none;
          position: relative;
          font-family: AvenirArabic-Heavy;
        }
      }
    }
  }
}
.contact-page {
  display: flex;
  height: 100vh;

  &.light-mode {
    background-color: #fff;
    color: #333;

    .contact-sidebar,
    .contact-sidebar-right {
      width: 24%;
      background: #fff;
    }
  }

  &.dark-mode {
    background-color: #070710;
    color: #fff;

    .contact-sidebar,
    .contact-sidebar-right {
      width: 24%;
      background: #070710;
    }
  }

  .contact-content {
    width: 52%; // Adjusted width to fit two sidebars
    padding: 2rem;

    h1 {
      font-size: 2rem;
      margin-bottom: 1.5rem;
    }

    .send-message,
    .find-us-online,
    .reach-us-directly {
      margin-bottom: 2rem;

      h2 {
        color: #f7268e;
        font-size: 1.5rem;
      }

      p {
        color: #c0c0c0;
        margin-top: 0.5rem;
        margin-bottom: 1rem;
      }
    }

    .contact-form {
      .form-row {
        display: flex;
        gap: 1rem;

        .form-group {
          flex: 1;
        }
      }

      .form-group {
        display: flex;
        flex-direction: column;
        margin-bottom: 1rem;

        label {
          margin-bottom: 0.5rem;
          font-size: 0.9rem;
        }

        input,
        textarea {
          padding: 0.75rem;
          border: 1px solid #6f3dd2;
          background: transparent;
          color: #fff;
          border-radius: 4px;

          &:focus {
            outline: none;
            border-color: #f7268e;
          }
        }
      }
      .btn-hover {
        background: #d51c78;
      }
      .btn-submit {
        padding: 0.75rem 1.5rem;
        background: #f7268e;
        border: none;
        border-radius: 4px;
        color: #fff;
        cursor: pointer;
        transition: background-color 0.3s;

        &:hover {
          background: #d51c78;
        }
      }
    }

    .social-icons {
      display: flex;
      gap: 1rem;

      .social-icon {
        display: flex;
        width: 50px;
        height: 50px;
      }
    }

    .email-link {
      color: #6f3dd2;
      text-decoration: underline;

      &:hover {
        color: #f7268e;
      }
    }
  }

  // Media Query to Hide Sidebars on Small Screens
  @media (max-width: 880px) {
    .contact-sidebar,
    .contact-sidebar-right {
      display: none;
    }

    .contact-content {
      width: 100%; // Take full width when sidebars are hidden
    }
  }
}
.btn-custom {
  background-color: #493276 !important;
}

.btn-custom:hover {
  background-color: #9244eb !important;
}
