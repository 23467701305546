.podcast-page {
  .rtl {
    direction: rtl;
  }
  .ltr {
    direction: ltr;
    text-align: left;

    // Ensure input fields and other components inside maintain LTR behavior
    input,
    .slug-url {
      direction: ltr;
      text-align: left;
    }
  }
  .from-group-wrapper {
    .text-danger.error {
      position: absolute !important;
      bottom: -20px !important;
    }
  }

  .row.pe-0 .error {
    top: unset;
  }

  .when-publish {
    margin: 0 !important;
  }
  .date-time-schedular {
    .date-time-schedule-wrapper {
      .col-sm-6 {
        padding-left: 0;
        padding-right: 0;
      }
      .date-picker-wrapper {
        .error {
          bottom: -5px !important;
        }
      }
    }
  }

  .form-btn {
    &.form-margin {
      display: flex;
      margin: 15px 0;
      button {
        margin-right: 15px;
        width: calc(50% - 15px);
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .input-group-container {
    position: relative;
    .input-group {
      position: relative;
      input[name='slugUrl'] {
        border-bottom-right-radius: 12px !important;
        border-top-right-radius: 12px !important;
      }
      svg {
        position: absolute;
        top: 50%;
        right: 15px;
        transform: translateY(-50%) !important;
        z-index: 99;
      }
      .success {
        color: #2ba639 !important;
      }
      .failure {
        color: #ff0019 !important;
      }
    }

    .error {
      bottom: -5px !important;
    }
  }

  .podcast-select {
    .podcast-select-column {
      .from-group-wrapper {
        span {
          margin-left: 0;
        }
      }
    }
  }

  .edit-save-btn {
    position: absolute !important;
    right: 0 !important;
    top: -85px !important;
  }
  @media (max-width: 991px) {
    .dropdown-group {
      display: flex;
      gap: 20px;
    }
    .dropdown-group,
    .from-group-wrapper {
      .text-danger.error {
        bottom: -27px !important;
      }
    }
  }
}

.upgrade-plan-modal-body {
  &.download-popup {
    .audio-mark-btn {
      display: flex;
      justify-content: center;
      margin-top: 20px;
      position: relative;
    }
    .enhance-btn {
      margin: 20px auto;
    }
  }
  svg {
    width: 100% !important;
  }
}

.featured-podcast {
  margin: 15px 47px 30px;
  .col-lg-6 {
    .calendar-container {
      .from-group-wrapper {
        .text-danger.error {
          position: absolute !important;
          bottom: -20px !important;
          left: 0 !important;
        }
      }
    }
  }
}

.warn-listener {
  display: flex;
  align-items: center;
  gap: 5px;
  svg {
    margin-bottom: 0.5rem;
  }
}

.podcast-uploaded-modal {
  .add-more-episodes {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #e9dafb;
    max-width: 500px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 14px;
    padding-bottom: 20px;

    p {
      min-width: 345px;
      font-size: 20px;
      font-weight: 800;
      line-height: 30px;
      letter-spacing: 0em;
      text-align: center;
    }
    .btn-container {
      display: flex;
      justify-content: center;
      button {
        margin-right: 10px;
        min-width: 200px;
        &:last-child {
          margin-left: 0;
        }
      }
    }
  }
}
