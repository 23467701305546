.modal-wrapper {
  .modal-content {
    .modal-close-btn {
      position: absolute;
      right: 7px;
      top: 7px;
      z-index: 1;
    }
    .modal-body {
      padding: 16px 40px 40px !important;
    }
  }
}
