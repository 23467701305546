.action-menu {
  .dropdown-toggle {
    &::before {
      display: none;
    }
  }

  .dropdown-menu {
    position: absolute;
    top: 108px !important;
    right: auto !important;
    bottom: auto !important;

    // Adjust alignment for LTR and RTL
    transform: translate(-90%, -70%) !important;

    // RTL-specific fixes
    [dir='rtl'] & {
      left: auto; /* Reset left alignment */
      right: 50%; /* Adjust for RTL positioning */
      transform: translate(40%, -70%) !important; /* Flip translate for RTL */
      margin-left: 0; /* Remove left margin in RTL */
    }

    .dropdown-menu-item {
      background: transparent;
      color: #1b0730;

      min-width: 180px;
      overflow: hidden;
      //&:first-child {
      //  padding-top: 10px;
      //}

      //&:last-child {
      //  padding-bottom: 10px;
      //}

      a,
      button {
        font-size: 14px;

        svg {
          margin-right: 6px;

          [dir='rtl'] & {
            margin-left: 0;
            margin-right: 6px; /* Flip margin in RTL */
          }

          path {
            fill: #fff !important;
            stroke: #fff !important;
            stroke-width: 0 !important;
          }
        }
      }

      .btn-action {
        background: none;
        border: none;
        color: #fff !important;
        outline: none;

        &:hover {
          background: var(--color-blackest-light);
        }

        // Force override 'btn' disabled background
        &:disabled {
          background-color: unset !important;
        }
      }
    }

    > :last-child {
      hr {
        display: none;
      }
    }
  }
}
