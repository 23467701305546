/*------------------------------
    Popup Menu Styles  
--------------------------------*/
.popup-mobile-menu {
  z-index: 9999;
  position: fixed;
  content: '';
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.8);
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.5s ease-out;
  .inner-popup {
    width: 320px;
    z-index: 999;
    position: absolute;
    background-color: var(--color-blackest);
    height: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    opacity: 0;
    left: -150px;
    transition: all 0.5s ease-out;

    .header-top {
      display: flex;
      border-bottom: 1px solid var(--color-border);
      align-items: center;
      justify-content: space-between;
      padding: 15px 20px;
      .logo {
        a {
          img {
            max-height: 30px;
          }
        }
      }
      .close-menu {
        .close-button {
          background: var(--color-primary);
          border: 0 none;
          color: var(--color-white);
          width: 40px;
          height: 40px;
          font-size: 21px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 100%;
        }
      }
    }
  }

  &.active {
    visibility: visible;
    opacity: 1;
    .inner-popup {
      opacity: 1;
      left: 0;
      overflow-y: auto;
    }
  }

  .mainmenu {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      margin: 5px 0;
      a {
        padding: 10px 12px;
        display: block;
        font-size: 16px;
        font-weight: 500;
        &.active {
          color: var(--color-primary) !important;
        }
      }
    }

    .has-droupdown {
      .submenu {
        padding: 0;
        max-width: 100%;
        list-style: none;
        padding-left: 14px;
        display: none;
        li {
          a {
            font-size: 15px;
            &.active {
              color: var(--color-primary) !important;
            }
          }
        }
      }
    }

    .has-menu-child-item {
      > a {
        position: relative;
        &::after {
          position: absolute;
          content: '\e9b1';
          font-family: 'feather' !important;
          right: 10px;
          top: 50%;
          transform: translateY(-50%);
          transition: 0.4s;
          font-size: 18px;
        }
        &.open {
          &::after {
            content: '\e996';
          }
        }
      }
    }

    .rainbow-megamenu {
      padding: 0;
      max-width: 100%;
      list-style: none;
      padding-left: 14px;
      display: none;
    }

    .mega-menu-item {
      list-style: none;
      padding: 0;
      margin: 0;
      padding-left: 0;
      li {
        a {
          font-size: 15px;
        }
        &:last-child {
          border-bottom: 1px solid var(--color-border);
        }
      }
    }

    .single-mega-item {
      &:last-child {
        .mega-menu-item {
          li {
            &:last-child {
              border-bottom-color: transparent;
            }
          }
        }
      }
    }
    .genarator-section {
      .genarator-card-group {
        li {
          flex-basis: 100%;
          & + li {
            border-top: none;
          }
        }
        .genarator-card {
          padding: 10px 15px 10px;
        }
      }
    }
    .header-menu-img {
      margin: 15px 0;
    }
  }

  .header-btn {
    text-align: center;
    margin-bottom: 20px;
    .btn-default {
      @media #{$large-mobile} {
        display: block;
        margin: 0 20px;
      }
    }
  }
  .content {
    padding: 10px 15px;
    .description {
      margin-bottom: 10px;
    }
  }
}
