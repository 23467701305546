.login-style label,
.form-style label,
.upload-title {
  font-family: 'Sora', sans-serif;
  line-height: 24px;
  margin-bottom: 12px;

  font-size: 18px;
}

.bg-img {
  z-index: 0;
}

.enhance-wave {
  margin-top: -15px;
  z-index: 0;
  width: 100%;
  height: 100%;
}

.before-enhance-wave {
  z-index: 999;
}

.time-enhance {
  font-size: 30px;
  color: var(--color-white);
}

.recording-timer {
  width: 100%;
  z-index: 999;
  color: #ffffff;
  font-family: 'Sora', sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 30px;
  padding-top: 6px;
}

.play-pause {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: -10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.styles_reactWaves__1M36F {
  margin: 0 !important;
  padding: 0 30px !important;
  width: 100% !important;
  height: 100% !important;

  .waveform {
    height: 100% !important;
  }
}

.wave {
  z-index: 999;
  width: 100%;
  height: 100%;

  wave {
    height: 100% !important;
  }
}

.audio-control-recording {
  width: 100%;
  display: flex;
  justify-content: center;
}

.audio-control-recording .stop-audio {
  margin-left: 10px;
  cursor: pointer;
}

.audio-control-recording {
  .resume-audio,
  .pause-audio {
    margin-right: 10px;
    cursor: pointer;
  }
}

img {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.resume-audio,
.pause-audio {
  &.enhance-play-pause {
    svg {
      height: 70px;
      width: 70px;
      cursor: pointer;
    }
  }
}

.big-height.record-audio .drop-zone .max-file {
  font-size: 24px !important;
  line-height: 24px;
  color: #1b0730;
  font-family: 'Sora', sans-serif;
}

// .bg-img {
//   background-repeat: no-repeat;
//   background-size: contain;
//   width: 100%;
//   height: 100vh;
//   background-image: url(../images/start-recording-new.png);
// }

.banner-image {
  height: 400px;
  width: 1600px;
}

.recording-container {
  .after-before-container {
    margin: 70px 0px;
  }
}

.big-height.record-audio {
  border: 2px solid var(--color-primary-3);
  background-color: var(--color-primary);
  padding: 10px;
  width: 100%;
  border-radius: var(--radius);
  max-width: 1600px;
  margin: 0 auto 30px;
  position: relative;

  &.old-audio {
    background-color: var(--color-extra02);
    color: var(--color-white);
    border-color: var(--color-extra02);
  }
}

.open-editor {
  border-radius: var(--radius);
  background: var(--color-secondary);
  color: var(--color-white);
  font-size: 18px;
  font-weight: 800;
  padding: 10px 15px;
  position: absolute;
  top: 10px;
  right: 10px;
}
.after-before-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  canvas {
    width: 96%;
    margin-left: 5px;
  }

  @media (max-width: 599px) {
    height: 100%;
  }
}

.big-height .drop-zone {
  max-width: unset;
  height: 340px;
}

.drop-zone-note {
  font-size: 18px !important;
  text-align: left;
}

.big-height .drop-zone .max-file {
  font-size: 14px !important;
}

.big-height.mb-40 {
  margin-bottom: 40px !important;
}

.mb-40 {
  margin-bottom: 40px;
}

.form-control::-webkit-input-placeholder {
  /* Edge */
  font-family: 'Sora', sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: #797ba1;
}

.form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-family: 'Sora', sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: #797ba1;
}

.form-control::placeholder {
  font-family: 'Sora', sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: #797ba1;
}

.tag {
  padding: 5px 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background: #797ba1;
  border-radius: 8px;
  color: #fff;
  display: block;
  align-items: center;
}

// #slugURLinput{
//   display: none;
// }

.close-tag-text {
  position: absolute;
  top: -18px;
  right: -15px;
  cursor: pointer;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  transform: scale(0.4);
}

.from-group-wrapper {
  margin-bottom: 20px;
}

.input-tag:focus {
  outline: none !important;
  color: #1b0730;
}

.enhance-btn {
  position: relative;
}
.enhance-btn .btn-style {
  margin: 0 auto;
}

.audio-enh {
  min-width: 204px !important;
}

.yes-no .enhance-btn {
  margin-top: 24px;
}

/******************************** Switch Toggle ********************************/

.switch-toggle {
  background: #eeefff;
  border-radius: 12px;
  padding: 18px 24px;
  font-weight: 800;
  font-size: 20px;
  line-height: 24px;
  color: #1b0730;
  font-family: AvenirArabic-Heavy;
  display: inline-block;
}

.custom-control {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.form-check-input:checked {
  background-color: #28a745;
  border-color: transparent !important;
}

.form-check-input:focus {
  box-shadow: none !important;
  outline: none;
}

.invite {
  display: flex;
  margin: 25px 25px 0px;
  font-size: 36px;
  justify-self: flex-start;
}

@media (max-width: 991px) {
  .big-height.mb-40 {
    margin-bottom: 40px !important;
  }
  .position-absolute {
    display: flex;
    margin-bottom: 10px;
  }
}

@media (max-width: 767px) {
  .yes-no {
    margin-bottom: 10px;
  }
  .btn-style,
  .reject-btn {
    font-size: 16px !important;
  }

  .podcast-successfully p {
    font-size: 24px !important;
    line-height: normal !important;
  }

  .yes-no p {
    font-size: 18px !important;
  }

  .box-width {
    padding: 15px;
  }

  .podcast-successfully.delete-popup img {
    width: 60px;
  }
}

.slug-valid {
  position: absolute;
  right: 26px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 99;
}

.top-n-14 {
  top: -14px;
}

.podcast-page,
.episode-page {
  .from-group-wrapper {
    .form-label {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 12px;
    }
  }
}

.row.pe-0 .error {
  top: -28px;
  margin-bottom: 10px;
}

.image-view {
  border-radius: 12px;
  height: 100%;
  width: 100%;
}

.date-time-schedule-wrapper {
  .calendar-icon {
    top: 20px !important;
  }
}

.open-editor-modal {
  .modal-content {
    .modal-close-btn {
      top: 15px;

      svg {
        height: 20px;
        width: 20px;
      }
    }
  }
}

.big-height.record-audio {
  position: relative;
}

.export-audio-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10;
  background: none;
  border: none;
  box-shadow: none;

  &::before,
  &::after {
    content: none;
    display: none;
  }
}

// Override SelectWrapper styles if needed
.export-audio-btn .select-wrapper {
  clip-path: none;
  background: none;
  border: none;
}

.icon-container {
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center; // Vertically centers the icon within the button
  justify-content: center; // Horizontally centers the icon within the container
  position: relative;
  top: 1px; // Move up slightly
  right: -4px; // Move to the right
}

.icon-container svg,
.icon-container img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.export-audio-dropdown {
  background: #e2f952 !important;
  color: #000 !important;
  font-size: 18px !important;
  font-weight: 550 !important;

  &::after {
    color: #000 !important;
  }
}

.audio-editor {
  // height: 100vh;
  .audio-editor-header {
    .dropdown-toggle {
      color: var(--color-primary-3) !important;

      img {
        width: 24px;
      }
    }
  }
  .d-flex.justify-content-center.align-items-center.gap-5,
  .d-flex.justify-content-between.align-items-center {
    flex-direction: column;
  }
  span.content {
    color: var(--color-primary);
    font-size: 13px;
  }
  .loader {
    position: relative;
  }
  .audio-editor-body {
    height: 94vh;
  }
}
