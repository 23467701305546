.privacy-policy {
  max-width: 70%;
  margin: auto;

  &.rtl {
    direction: rtl;
    text-align: right;

    h3,
    h4,
    p {
      text-align: right;
    }
  }

  &.ltr {
    direction: ltr;
    text-align: left;

    h3,
    h4,
    p {
      text-align: left;
    }
  }

  .banner-area {
    padding: 0;
  }

  .title {
    color: var(--color-primary-ligther);
  }
}

.active-light-mode {
  .privacy-policy {
    .title {
      color: var(--color-primary);
    }
  }
}
