/*---------------------
    Portfolio Styles  
-----------------------*/
.rainbow-portfolio-area {
  position: relative;
  overflow: hidden;
}

.rainbow-card {
  overflow: hidden;
  @extend %transition;
  .inner {
    .thumbnail {
      position: relative;
      .card-image {
        overflow: hidden;
        a {
          display: block;
          overflow: hidden;
          @extend %radius-small;
        }
        img {
          overflow: hidden;
          @extend %transition;
          @extend %radius-small;
        }
      }
    }
    .content {
      padding-top: 20px;
      .title {
        a {
          @extend %transition;
        }
      }
      .subtitle {
        text-transform: capitalize;
      }
    }
  }
  &:hover {
    transform: translateY(-4px);
    .inner {
      .thumbnail {
        .card-image {
          img {
            transform: scale(1.05);
          }
        }
      }
    }
  }
}

/*------------------
    No Overlay  
--------------------*/
.no-overlay {
  .rainbow-card {
    .inner {
      .thumbnail {
        .rainbow-overlay {
          display: none;
        }
      }
    }
  }
}

/*----------------------
    Box Grid Layout  
------------------------*/
.box-grid-layout {
  .rainbow-card {
    border-radius: var(--radius-small);
    background-color: var(--color-blackest);
    .inner {
      .thumbnail {
        .card-image {
          a {
            border-radius: var(--radius-small) var(--radius-small) 0 0;
            img {
              border-radius: var(--radius-small) var(--radius-small) 0 0;
            }
          }
        }
      }
      .content {
        padding: 25px;
      }
    }
  }
}

/*-----------------------------
    Filter Button Style  
-------------------------------*/

.filter-button-default {
  display: flex;
  justify-content: center;
  margin: 0 -5px;
  flex-wrap: wrap;
  button {
    border: 0 none;
    padding: 10px 20px;
    text-transform: capitalize;
    background: transparent;
    color: var(--color-body);
    @extend %radius-small;
    @extend %transition;
    margin: 0 5px;
    @media #{$sm-layout} {
      padding: 10px 10px;
    }
    &.is-checked,
    &:hover {
      background-color: var(--color-primary);
      color: var(--color-white) !important;
    }
  }
}

/*--------------------------------
    Portfolio Read More Icon  
---------------------------------*/

.rainbow-load-more {
  a,
  button {
    &.btn-default {
      &.btn-icon {
        .icon {
          top: 0px;
          margin-left: -2px;
          svg {
            width: 14px;
            height: 20px;
          }
        }
      }
    }
  }
}

/*----------------------------------
    Portfolio Grid Layout  
----------------------------------*/

.grid-metro2 {
  margin: 0 -15px;
  .portfolio-2 {
    width: 50%;
    padding: 0 15px;
    margin-top: 30px;
    @media #{$large-mobile} {
      width: 100%;
    }
  }
  .resizer {
    width: 50%;
    @media #{$large-mobile} {
      width: 100%;
    }
  }
}

.grid-metro3 {
  margin: 0 -15px;
  .portfolio-3 {
    width: 33.33%;
    padding: 0 15px;
    margin-top: 30px;
    @media #{$md-layout} {
      width: 50%;
    }
    @media #{$sm-layout} {
      width: 50%;
    }
    @media #{$large-mobile} {
      width: 100%;
    }
  }
  .resizer {
    width: 33.33%;
    @media #{$md-layout} {
      width: 50%;
    }
    @media #{$sm-layout} {
      width: 50%;
    }
    @media #{$large-mobile} {
      width: 100%;
    }
  }
}

.grid-metro4 {
  margin: 0 -15px;
  .portfolio-4 {
    width: 25%;
    padding: 0 15px;
    margin-top: 30px;
    @media #{$md-layout} {
      width: 50%;
    }
    @media #{$sm-layout} {
      width: 50%;
    }
    @media #{$large-mobile} {
      width: 100%;
    }
  }
  .resizer {
    width: 25%;
    @media #{$md-layout} {
      width: 50%;
    }
    @media #{$sm-layout} {
      width: 50%;
    }
    @media #{$large-mobile} {
      width: 100%;
    }
  }
  &.no-gutter {
    margin: 0;
    .portfolio-4 {
      padding: 0;
      margin-top: 0;
    }
    .rainbow-card {
      border-radius: 0;
      border: 0 none;
    }
  }
}

.no-gutter .rainbow-card .inner .thumbnail a img,
.no-gutter .rainbow-card .inner .thumbnail a {
  border-radius: 0;
}

.no-gutter .rainbow-card:hover {
  transform: none;
}
