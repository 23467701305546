.enable-microphone-popup {
  .modal-close-btn {
    position: absolute;
    top: 30px;
    right: 30px;
    z-index: 1;
  }
  .modal-body {
    padding-top: 70px !important;
    .title {
      max-width: 500px !important;
      margin-top: 25px !important;
    }
    .body-content {
      max-width: 600px;
      font-weight: 500;
      line-height: 37.2px !important;
      font-size: 24px !important;
      font-family: AvenirArabic-Medium;
      color: #5d5962 !important;
      padding-bottom: 10px;
    }
  }
}
