.tooltip-inner {
  background-color: #626473 !important;
  color: #ffffff !important;
  border: 1px solid #626473 !important;
  padding: 7px 10px !important;
  border-radius: 6px !important;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  max-width: 400px;
}
