/*--------------------------
    About Styles  
---------------------------*/
.about-style-4 {
  .title {
    font-weight: 400;
    margin-bottom: 35px;
    strong {
      color: var(--color-primary);
    }
  }

  .feature-list {
    padding: 0;
    margin: 0;
    list-style: none;
    li {
      display: flex;
      .icon {
        background: var(--color-primary);
        width: 30px;
        height: 30px;
        border-radius: 100%;
        color: var(--color-white);
        text-align: center;
        min-width: 30px;
        i {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
        }
      }
      .title-wrapper {
        margin-left: 13px;
        .title {
          font-size: 18px;
          margin: 0;
        }
        .text {
          font-size: 16px;
          margin-bottom: 20px;
          max-width: 80%;
          margin-top: 13px;
        }
      }
    }
  }
}

// About Styles 5

.about-style-5 {
  position: relative;
  z-index: 2;
  .about-wrapper {
    margin-top: -150px;
    background: var(--color-blackest);
    border-radius: 10px;
    padding: 15px;
    @media #{$md-layout} {
      margin-top: 80px;
      padding: 15px 15px 30px 15px;
    }
    @media #{$sm-layout} {
      margin-top: 80px;
      padding: 15px 15px 30px 15px;
    }
    .thumbnail {
      img {
        border-radius: 10px;
      }
    }
    .content {
      .inner {
        padding: 0 40px;
        @media #{$sm-layout} {
          padding: 0;
        }
        .title {
          font-size: 30px;
          margin-bottom: 13px;
        }
        p {
          margin-bottom: 20px;
          width: 80%;
        }
      }
    }
  }
}

.contact-address {
  padding: 0;
  margin: 0;
  list-style: none;
  li {
    font-size: 16px;
    i {
      color: var(--color-primary);
      margin-right: 10px;
    }
  }
}

// About Styles 6
.about-style-6 {
  .rbt-progress-style-1,
  .wrapper {
    position: relative;
    z-index: 2;
    max-width: 850px;
    margin: 0 auto;
    border-radius: 10px;
  }
  .wrapper {
    padding: 50px;
    @media #{$md-layout} {
      padding: 20px;
    }
    @media #{$sm-layout} {
      padding: 20px;
    }
    &::after {
      position: absolute;
      content: '';
      left: 0;
      top: 0;
      background: var(--color-lessdark);
      width: 100%;
      height: 100%;
      z-index: -1;
      opacity: 0.6;
      border-radius: 10px;
    }
  }

  .thumbnail {
    img {
      max-height: 200px;
    }
  }

  .subtitle {
    font-size: 18px;
    font-weight: 500;
  }
  .maintitle {
    font-size: 30px;
    line-height: 1.3;
    margin-top: 17px;
    margin-bottom: 25px;
  }
}
