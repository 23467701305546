#custom-waveform {
  width: 47%;
  height: 100%;
  z-index: 0;
}
.btn-mic {
  border: none;
  padding: 0;
  border-radius: 100%;
  width: 50px;
  height: 50px;
  font-size: 1.5em;
  color: #fff;
  margin: 0;
  background: var(--color-secondary);
  position: relative;
  z-index: 999;
  display: inline-block;
  line-height: 50px;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  // -ms-touch-action: manipulation;
  // touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
}
.pulse-ring {
  content: '';
  width: 50px;
  height: 50px;
  background: var(--color-secondary);
  border: 5px solid var(--color-secondary);
  border-radius: 50%;
  position: absolute;
  animation: pulsate infinite 1.5s;
}
@keyframes pulsate {
  0% {
    -webkit-transform: scale(1, 1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1.5, 1.5);
    opacity: 0;
  }
}
.container-microphone {
  width: 100%;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}
.enhance {
  margin: 0px !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 5px;
  border-color: transparent;
  border-radius: 10px;
  opacity: 10;
  background-color: var(--color-secondary);
}
.line {
  position: absolute;
  z-index: 999;
  width: 90%;
  left: 5%;
  top: 60.2%;
  height: 5px;
  border-color: var(--color-secondary);
  border-radius: 10px;
  opacity: 10;
  background-color: var(--color-secondary);
}
.pulse-ring2 {
  border-radius: 50%;
  position: absolute;
  width: 100%;
  height: 50px;
  transform: scale(1.5, 1.5);
  background: transparent;
  border: 1px solid var(--color-secondary);
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .recording-timer {
    margin: 0px !important;
    transform: scale(0.7);
  }
  .audio-wave {
    position: relative;
    top: -25px;
  }
  .audio-control-recording {
    transform: scale(0.7);
    position: relative;
    top: -50px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .btn-mic {
    transform: scale(0.75);
  }
  .play-pause {
    margin: 0px;
    transform: scale(0.6) !important;
    position: relative;
    top: -40px;
    margin-top: 0px !important;
    @media (max-width: 440px) {
      top: -30px;
    }
  }
  .time-enhance {
    transform: scale(0.8);
  }
  .after-before-container {
    margin: 0px;
  }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .recording-timer {
    margin: 30px !important;
    transform: scale(1);
  }
  .audio-wave {
  }
  .audio-control-recording {
    transform: scale(1);
  }
  .btn-mic {
    transform: scale(1);
  }
  .after-before-container {
    margin: 30px 0px 0px 0px !important;
  }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .after-before-container {
    margin: 0px !important;
  }
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .recording-timer {
    transform: scale(1);
  }
  .audio-wave {
    position: relative;
    top: -25px;
  }
  .audio-control-recording {
    transform: scale(1);
    position: relative;
    top: -50px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .btn-mic {
    transform: scale(1);
  }
}
@media (max-width: 1399px) and (min-width: 1200px) {
  .play-pause {
    margin-top: 0px;
  }
}
@media only screen and (max-width: 1399px) {
  .big-height {
    &.record-audio {
      .after-before-container {
        @media only screen and (max-width: 1289px) {
          margin: 24px 0px 0px 0px !important;
          .resume-audio,
          .pause-audio {
            &.enhance-play-pause {
              svg {
                height: 50px;
                width: 50px;
              }
            }
          }
        }
        .time-enhance {
          font-size: 24px;
        }
        @media only screen and (max-width: 1289px) {
          .play-pause {
            //   margin-top: 10px;
          }
        }
      }
      .edit-btn {
        @media (max-width: 450px) {
          right: 4px;
          top: 8px;
          .open-editor {
            font-size: 10px !important;
            padding: 4px 6px;
            border-radius: 6px !important;
            svg {
              width: 12px !important;
            }
          }
        }
        .open-editor {
          font-size: 12px;
          svg {
            width: 16px;
          }
        }
      }
    }
  }
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1290px) {
  .recording-timer {
    margin: 30px 0px !important;
    transform: scale(1.5);
  }
  .audio-wave {
    position: relative;
    top: -60px;
  }
  .audio-control-recording {
    transform: scale(1);
    position: relative;
    top: -80px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .btn-mic {
    transform: scale(1.3);
  }
}
.hr {
  width: 100%;
  border: 1px solid #fff !important;
  position: relative;
  top: -18px;
  left: -1px;
  opacity: 1;
}

.after-before-container {
  input[type='range'] {
    -webkit-appearance: none;
    background-color: #ddd;
    height: 4px;
    overflow: hidden;
    width: 98.86%;
    position: relative;
    top: -36px;
    cursor: pointer;
    margin-left: 2px;
  }

  input[type='range']::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    height: 4px;
  }

  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    background: rgb(226, 249, 82);
    border-radius: 50%;
    box-shadow: -1000px 0 0 1000px rgb(226, 249, 82);
    cursor: pointer;
    height: 4px;
    width: 4px;
    border: 0;
  }

  input[type='range']::-moz-range-thumb {
    background: rgb(226, 249, 82);
    border-radius: 50%;
    box-shadow: -1000px 0 0 1000px rgb(226, 249, 82);
    cursor: pointer;
    height: 4px;
    width: 4px;
    border: 0;
  }

  input[type='range']::-moz-range-track {
    background-color: #ddd;
  }
  input[type='range']::-moz-range-progress {
    background-color: rgb(226, 249, 82);
    height: 4px;
  }
  input[type='range']::-ms-fill-upper {
    background-color: #ddd;
  }
  input[type='range']::-ms-fill-lower {
    background-color: rgb(226, 249, 82);
  }
}
