.icon-btn {
  background: none !important;
  border: none !important;
  outline: none !important;
  padding: 0;
  margin-right: 0.75rem;
  &:hover {
    background: none !important;
    border: none !important;
    outline: none !important;
  }
}
