.tag-wrapper {
  label {
    width: 100%;

    .tag-box {
      .tag {
        padding: 5px 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        background: #797ba1;
        border-radius: 8px;
        color: #fff;
        display: flex;
        gap: 10px;
        align-items: center;
      }

      .close-tag-text {
        position: absolute;
        top: -18px;
        right: -28px;
        cursor: pointer;
        height: 40px;
        width: 40px;
        border-radius: 50%;
        transform: scale(0.4);
      }
    }

    .error {
      top: unset;
      bottom: -3px;
    }
  }
  margin-bottom: 25px;
}
