/*=====================
All Extend Here
=======================*/
%color-primary {
  color: var(--color-primary) !important;
}

%color-primary {
  color: var(--color-primary) !important;
}

%color-primary {
  color: var(--color-primary) !important;
}

%color-secondary {
  color: var(--color-secondary) !important;
}
%color-tertiary {
  color: var(--color-tertiary) !important;
}
%color-primary {
  color: var(--color-primary) !important;
}
%color-primary {
  color: var(--color-primary) !important;
}
%color-primary {
  color: var(--color-primary) !important;
}
%color-primary-darker {
  color: var(--color-primary-darker) !important;
}
%color-secondary-darker {
  color: var(--color-secondary-darker) !important;
}
%color-primary-darker {
  color: var(--color-primary-darker) !important;
}
%color-primary-darker {
  color: var(--color-primary-darker) !important;
}
%color-heading {
  color: var(--color-heading) !important;
}
%color-body {
  color: var(--color-body) !important;
}
%color-dark {
  color: var(--color-dark) !important;
}
%color-darker {
  color: var(--color-darker) !important;
}
%color-darkest {
  color: var(--color-darkest) !important;
}
%color-black {
  color: var(--color-black) !important;
}
%color-blacker {
  color: var(--color-blacker) !important;
}
%color-blackest {
  color: var(--color-blackest) !important;
}
%color-border {
  color: var(--color-border) !important;
}
%color-gray {
  color: var(--color-gray) !important;
}
%color-midgray {
  color: var(--color-midgray) !important;
}
%color-light {
  color: var(--color-light) !important;
}

%color-lighter {
  color: var(--color-lighter) !important;
}
%color-lightest {
  color: var(--color-lightest) !important;
}
%color-white {
  color: var(--color-white) !important;
}

//===== BG Ncc Colors =====//

%bg-primary-color {
  background: var(--color-primary);
}

%bg-secondary-color {
  background: var(--color-secondary);
}

%bg-tertiary-color {
  background-color: var(--color-tertiary);
}

%bg-color-primary {
  background-color: var(--color-primary) !important;
}

%bg-color-primary {
  background-color: var(--color-primary) !important;
}

%bg-color-primary {
  background-color: var(--color-primary) !important;
}

%bg-color-secondary {
  background-color: var(--color-secondary) !important;
}
%bg-color-tertiary {
  background-color: var(--color-tertiary) !important;
}
%bg-color-primary {
  background-color: var(--color-primary) !important;
}
%bg-color-primary {
  background-color: var(--color-primary) !important;
}
%bg-color-primary {
  background-color: var(--color-primary) !important;
}
%bg-color-primary-darker {
  background-color: var(--color-primary-darker) !important;
}
%bg-color-secondary-darker {
  background-color: var(--color-secondary-darker) !important;
}
%bg-color-primary-darker {
  background-color: var(--color-primary-darker) !important;
}
%bg-color-primary-darker {
  background-color: var(--color-primary-darker) !important;
}
%bg-color-heading {
  background-color: var(--color-heading) !important;
}
%bg-color-body {
  background-color: var(--color-body) !important;
}
%bg-color-dark {
  background-color: var(--color-dark) !important;
}
%bg-color-darker {
  background-color: var(--color-darker) !important;
}
%bg-color-darkest {
  background-color: var(--color-darkest) !important;
}
%bg-color-black {
  background-color: var(--color-black) !important;
}
%bg-color-blacker {
  background-color: var(--color-blacker) !important;
}
%bg-color-blackest {
  background-color: var(--color-blackest) !important;
}
%bg-color-border {
  background-color: var(--color-border) !important;
}
%bg-color-gray {
  background-color: var(--color-gray) !important;
}
%bg-color-midgray {
  background-color: var(--color-midgray) !important;
}
%bg-color-light {
  background-color: var(--color-light) !important;
}
%bg-color-lighter {
  background-color: var(--color-lighter) !important;
}
%bg-color-lightest {
  background-color: var(--color-lightest) !important;
}
%bg-color-white {
  background-color: var(--color-white) !important;
}

/* Radius */
%radius-small {
  border-radius: var(--radius-small) !important;
}

.radius-small {
  border-radius: var(--radius-small) !important;
}

%radius {
  border-radius: var(--radius) !important;
}

%radius-big {
  border-radius: var(--radius-big) !important;
}

%radius-bigger {
  border-radius: var(--radius-bigger) !important;
}

.radius-bigger {
  @extend %radius-bigger;
}

/* Font Weight */

%w-300 {
  font-weight: 300 !important;
}
%w-400 {
  font-weight: 400 !important;
}
%w-500 {
  font-weight: 500 !important;
}
%w-600 {
  font-weight: 600 !important;
}
%w-700 {
  font-weight: 700 !important;
}
%w-800 {
  font-weight: 800 !important;
}
%w-900 {
  font-weight: 900 !important;
}

/* Shadows */

%shadow-primary {
  box-shadow: var(--shadow-primary);
}
%shadow-light {
  box-shadow: var(--shadow-light);
}
%shadow-lighter {
  box-shadow: var(--shadow-lighter);
}

// Others

%box-shadow {
  box-shadow: var(--shadow-primary);
}

/*=============== Style Css =============*/

%liststyle {
  padding: 0;
  margin: 0;
  list-style: none;
}

%transition {
  transition: var(--transition);
}

%bgImagePosition {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.bgImagePosition {
  @extend %bgImagePosition;
}

%rbt-dropdown-type-1 {
  position: absolute;
  top: 100%;
  left: 0;
  visibility: hidden;
  clip: rect(0px, 200vw, 0, 0px);
  opacity: 0;
  transition:
    opacity 0.4s linear,
    clip 0.6s linear,
    visibility 0s 0.4s;
  transform: translateZ(0);
}

%rbt-hover-dropdown-type-1 {
  top: 100%;
  visibility: visible;
  opacity: 1;
  clip: rect(0px, 100vw, 200vh, -30px);
  transition:
    clip 0.6s linear,
    opacity 0.4s linear;
}
