.loader {
  display: flex;
  align-items: center;
}

.bar {
  display: inline-block;
  width: 3px;
  height: 20px;
  background-color: #9244eb;
  border-radius: 10px;
  animation: scale-up4 2s linear infinite;
}

button {
  .bar {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    background-color: aliceblue !important;

    &:nth-of-type(even) {
      height: 25px;
    }

    &:nth-of-type(odd) {
      height: 15px;
    }
  }
}

.aliceblue-loader {
  .bar {
    background-color: aliceblue !important;
  }
}

.theme-loader {
  .bar {
    background-color: #9244eb !important;
  }
}

.bar:nth-child(2) {
  height: 35px;
  margin: 0 5px;
  animation-delay: 0.15s;
}

.bar:nth-child(3) {
  animation-delay: 0.3s;
}

.bar:nth-child(4) {
  margin: 0 5px;
  height: 35px;
  animation-delay: 0.45s;
}

.bar:nth-child(5) {
  animation-delay: 0.6s;
}

.screen-loader {
  position: absolute;
  z-index: 999;
  background: rgb(8 5 16 / 64%);
  width: 100%;
  top: 0;
  left: 0;
}

@keyframes scale-up4 {
  20% {
    background-color: #9244eb;
    transform: scaleY(1.5);
  }

  40% {
    transform: scaleY(1);
  }
}
