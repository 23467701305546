/* Button Styles  */

.button-group {
  margin: -10px;

  a,
  button {
    margin: 10px;
  }
}

.btn:disabled,
.btn.disabled,
fieldset:disabled .btn {
  background-color: var(--color-extra02) !important;
  color: var(--color-white);
}
a,
button,
div {
  &.btn-default {
    padding: 0 28px;
    border-style: solid;
    border-width: 0;
    border-color: transparent;
    border-radius: var(--radius);
    background-color: var(--color-primary);
    //background: linear-gradient(94deg,#dd00ac 10.66%,#7130c3 53.03%,#410093 96.34%,rgba(255,0,238,.26) 191.41%,rgba(255,59,212,0) 191.43%);
    background-size: 200% auto;
    transition-duration: 300ms;
    color: var(--color-white);
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    height: 50px;
    line-height: 50px;
    letter-spacing: 0.5px;
    width: auto;
    transition: 0.4s;

    &.tertiary-btn {
      background-color: var(--color-extra02) !important;
      color: var(--color-white);
    }
    &.bg-solid-primary {
      background: var(--color-primary);

      &:hover {
        box-shadow: 0 0 20px 5px rgba(112, 100, 233, 0.1);
      }
    }

    &.shadow-primary {
      box-shadow: var(--shadow-primary);
      filter: brightness(125%);
    }

    &.bg-gradient-secondary {
      background: linear-gradient(to right, var(--color-secondary), var(--color-secondary));
    }

    &.bg-gradient-tertiary {
      background: linear-gradient(
        to right,
        var(--color-tertiary-gradient-start),
        var(--color-tertiary-gradient-end)
      );
    }

    &.bg-light-gradient {
      position: relative;
      background: radial-gradient(85% 120% at 50% 125%, #ff3bd4 0.74%, #7130c3 65%, #0f1021 100%);
      overflow: hidden;
      transition: var(--transition);

      .has-bg-light {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s ease-in-out;
        background: radial-gradient(85% 142% at 50% 75%, #ff3bd4 0.74%, #7130c3 65%, #0f1021 100%);
      }

      & > span {
        position: relative;
      }

      &:hover {
        & .has-bg-light {
          opacity: 1;
          visibility: visible;
        }

        transform: translateY(-5px);
        box-shadow: 0 24px 25px -21px #ce38cf;
      }
    }

    &.btn-border-secondary {
      border: 1px solid var(--color-secondary);
      padding: 0 30px !important;
    }

    &.btn-icon {
      .icon {
        position: relative;
        top: 2px;
        padding-left: 4px;

        i {
          width: 20px;
          height: 20px;
        }
      }
    }

    &:hover {
      box-shadow: var(--shadow-primary);
      //-webkit-filter: brightness(125%);
      //filter: brightness(125%);
      color: var(--color-white);
      text-decoration: none;
      background-color: var(--color-primary);
    }
    &:focus,
    &:active,
    &:focus-visible {
      background-color: var(--color-primary) !important;
    }

    &.btn-small {
      height: 40px;
      line-height: 40px;
      padding: 0 20px;
      font-size: 14px;
      font-weight: 500;
    }

    &.btn-medium {
      height: 65px;
      line-height: 65px;
      padding: 0 40px;
    }

    &.btn-large {
      height: 60px;
      line-height: 60px;
      padding: 0 37px;
      font-size: 17px;
      @media #{$sm-layout} {
        padding: 0 25px;
      }
    }

    &.btn-extra-large {
      height: 90px;
      line-height: 90px;
      padding: 0 70px;
      font-size: 20px;
      @media #{$sm-layout} {
        padding: 0 44px;
      }
    }

    &.btn-border {
      background-image: inherit;
      border-color: var(--color-border);
      color: var(--color-heading);
      border-width: 2px;
      border-style: solid;
      line-height: 46px;

      &.btn-small {
        line-height: 37px;
      }

      &.btn-medium {
        line-height: 61px;
      }

      &.btn-large {
        line-height: 56px;
      }

      &.btn-extra-large {
        line-height: 87px;
      }

      &:hover {
        background-color: var(--color-primary);
        border-color: var(--color-primary);
        color: var(--color-heading);
      }
    }

    &.text-underline {
      background-image: inherit;
      border-color: var(--color-white);

      span {
        position: relative;
        display: inline-block;
        line-height: 30px;
      }

      &:hover {
        box-shadow: none;
        background-color: var(--color-primary);
        border-color: var(--color-primary);
      }
    }

    &.round {
      border-radius: 100px;
    }

    &.square {
      border-radius: 0;
    }

    &.rounded-player {
      border-radius: 100%;
      width: 100px;
      height: 100px;
      padding: 0;
      line-height: 100px;
      font-size: 30px;
      display: inline-block;
      @media #{$sm-layout} {
        width: 70px;
        height: 70px;
        line-height: 70px;
      }

      span {
        i {
          position: relative;
          top: 1px;
          left: 2px;
        }
      }

      &.border {
        background-image: inherit;
        border-color: var(--color-white) !important;
        border-width: 2px !important;

        &:hover {
          background-color: var(--color-primary);
          border-color: var(--color-primary) !important;
        }
      }

      &.sm-size {
        width: 70px;
        height: 70px;
        line-height: 70px;
      }
    }

    &.bg-white-dropshadow {
      background-color: rgba(255, 255, 255, 0.4);
      backdrop-filter: blur(7.5px);
    }

    &.active {
      background-color: var(--color-bg-2) !important;
      color: var(--color-white);
    }
  }
}

.btn-read-more {
  span {
    position: relative;
    color: var(--color-heading);
    display: inline-block;
    line-height: 35px;
    transition: 0.3s;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      height: 2px;
      background-color: var(--color-border);
      transition: 0.3s;
      width: 100%;
      bottom: 3px;
    }

    &::before {
      position: absolute;
      content: '';
      left: auto;
      bottom: 2px;
      background: var(--color-primary);
      width: 0;
      height: 2px;
      transition: 0.3s;
      right: 0;
    }
  }

  &:hover {
    span {
      color: var(--color-primary);

      &::before {
        width: 100%;
        left: 0;
        right: auto;
      }
    }
  }
}

.rainbow-badge {
  display: inline-block;
  background-color: var(--color-primary);
  padding: 0 16px;
  height: 30px;
  line-height: 30px;
  border-radius: 100px;
  color: #fff;
  font-size: 12px;
  letter-spacing: 0.5px;

  &.position-top-right {
    position: absolute;
    right: 10px;
    top: 10px;
  }
}

.rainbow-badge-card {
  background-color: transparent;
  background: linear-gradient(
    94deg,
    #dd00ac 10.66%,
    #7130c3 53.03%,
    #410093 96.34%,
    rgba(255, 0, 238, 0.26) 191.41%,
    rgba(255, 59, 212, 0) 191.43%
  );
  padding: 1px 10px;
  border-radius: 100px;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  display: inline-block;
  position: relative;
  z-index: 2;
  letter-spacing: 0.5px;

  &.badge-border {
    border: 2px solid var(--color-border);

    &::before {
      content: none;
    }
  }

  &.position-top-right {
    position: absolute;
    right: 10px;
    top: 10px;
  }
}

.btn-collaborate-play {
  i {
    height: 40px;
    width: 40px;
    line-height: 40px;
    text-align: center;
    border-radius: 500px;
    display: inline-block;
    background: linear-gradient(
        95deg,
        var(--color-primary) 15%,
        var(--color-tertiary) 45%,
        var(--color-primary) 75%,
        var(--color-secondary) 100%
      )
      95% / 200% 100%;
  }

  span {
    display: inline-block;
  }
}

.btn-collaborate-play {
  height: 30px;
  width: 30px;
  line-height: 30px;
  align-items: center;
  transition: var(--transition);

  span {
    margin-left: 15px;
  }

  &:hover {
    color: var(--color-white);
  }
}

.highlight {
  &:hover {
    .copy-to-clipboard-button {
      opacity: 1;
      transition: all 0.2s linear;
    }
  }
}

.copy-to-clipboard-button {
  position: absolute;
  right: 16px;
  top: 16px;
  background: rgba(224, 224, 224, 0.2);
  color: #bbb;
  transition: all 0.2s linear;
  font-size: 14px;
  padding: 4px 13px;
  border-radius: 15px;
  opacity: 0;
  outline: 0;
  border: 0;
}

button {
  border: unset;
  background: unset;
}
