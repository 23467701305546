.from-group-wrapper {
  //margin-bottom: 25px;

  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none; /* Remove the default arrow buttons */
    margin: 0; /* Remove the margin */
  }

  .error {
    top: unset !important;
    bottom: -20px;

    @media (max-width: 992px) {
      top: unset !important;
      bottom: -30px;
    }
  }

  .forgot-password-link {
    position: absolute;
    bottom: -25px;
    right: 10px;
    font-size: 15px;
    //color: var(--color-primary);

    &:hover {
      color: var(--color-primary-3);
    }
  }
}

.react-datepicker__input-container {
  .form-control {
    padding: 11px 15px;
  }
}
