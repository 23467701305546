.form-btn {
  &.form-margin {
    display: flex;
    margin: 15px 0;
    button,
    .calendar-container {
      margin-right: 15px;
      width: calc(50% - 15px);
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
