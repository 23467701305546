.podcast-wrapper {
  //display: grid;
  //grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  //gap: 20px;
  //
  //@media (max-width: 991px) {
  //  grid-template-columns: 1fr;
  //}
  //
  //&.lg {
  //  grid-template-columns: repeat(3, 1fr);
  //  @media (min-width: 1870px) {
  //    grid-template-columns: repeat(4, 1fr);
  //  }
  //  @media (max-width: 1199px) {
  //    grid-template-columns: repeat(3, 1fr);
  //  }
  //  @media (max-width: 991px) {
  //    grid-template-columns: repeat(2, 1fr);
  //  }
  //  @media (max-width: 780px) {
  //    grid-template-columns: 1fr;
  //  }
  //}
}

button.podcast-card {
  border: unset !important;
}

.podcast-card {
  border-radius: var(--radius);
  color: var(--color-white);
  padding: 20px;
  background-color: var(--color-primary);
  position: relative;
  cursor: pointer;
  transition: 0.4s ease-out;

  .podcast-title-text {
    width: 100%;
  }
  //.podcast-description{
  //  position: relative;
  //  z-index: 3;
  //  color: var(--color-white);
  //  transform: translateY(30px);
  //  transition: 0.5s;
  //  display: none;
  //}
  //&:after {
  //  content: '';
  //  background-color: var(--color-bg-2);
  //  width: 100%;
  //  height: 100%;
  //  display: block;
  //  position: absolute;
  //  top: 0;
  //  left: 0;
  //  border-radius: var(--radius);
  //  opacity: 0;
  //  z-index: 2;
  //  transition: 0.5s;
  //}
  //
  //&:hover{
  //  &:after {
  //    opacity: 1;
  //  }
  //  .podcast-description{
  //    position: absolute;
  //    top: 0;
  //    left: 0;
  //    padding: 0 30px;
  //    display: flex;
  //    align-items: center;
  //    justify-content: center;
  //    z-index: 3;
  //    color: var(--color-white) !important;
  //    font-size: 16px;
  //  }
  //}

  .podcast-image-box {
    border: 5px solid #5a3690;
    border-radius: 50%;
    width: 130px;
    min-height: 130px;
    background: var(--color-bg-2);

    .image-link {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 120px;
    }
    img.podcast-avatar {
      border-radius: 50%;
      width: 100%;
      height: 100%;
      &.inner-empty-image {
        width: 70px;
        border-radius: unset;
      }
      &.empty-image {
        width: 60px;
        border-radius: unset;
      }
    }
  }

  .podcast-title-description {
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
    text-align: left;

    h4,
    p {
      color: var(--color-white) !important;
      font-weight: 500;
    }
  }

  .podcast-info {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 8px;

    li {
      margin: 0;
    }

    p {
      font-size: 14px;
    }

    img,
    svg {
      width: 20px;
      height: 20px;
    }
  }

  .podcast-action {
    flex-wrap: wrap;

    .action-text {
      display: none;
    }

    .action-menu .dropdown-menu {
      top: -13px !important;
      margin-left: 0;
      left: 122px !important;
      .dropdown-menu-item {
        min-width: 150px;

        svg {
          width: 30px;
        }
      }
    }

    img,
    svg {
      width: 20px;
    }

    li {
      margin: 0;
    }

    button {
      background-color: unset;
      border: none;
    }
  }

  &.lg {
    min-height: 421px;

    > div {
      min-height: inherit;
    }
    .podcast-info {
      gap: 20px;
    }
    .podcast-image-box {
      margin-bottom: 15px;
    }

    //.action-text {
    //  display: flex;
    //}

    &.podcast-card {
      padding: 30px 50px;

      //max-width: 350px;
    }
    .mt--15 {
      margin-top: 50px;
    }
    .podcast-title-description {
      width: 100%;
    }
    .podcast-title-text {
      //max-width: 70%;
      @media (max-width: 991px) {
        // For screens smaller than 991
        max-width: 100%;
      }
    }
  }

  &.light {
    svg {
      path {
        fill: var(--color-white);
      }
    }
  }
  &.dark {
    h4,
    p {
      color: var(--color-primary) !important;
    }

    svg {
      path {
        fill: var(--color-primary);
      }
    }
  }
}
