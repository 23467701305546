.date-picker-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;

  .form-control.big-datepicker-input {
    padding-right: 2.5rem; /* Default padding for the calendar icon */

    [dir='rtl'] & {
      padding-right: 3.5rem; /* Add extra space for RTL */
    }
  }

  .form-label {
    margin-right: 0;

    [dir='rtl'] & {
      margin-right: 10px; /* Add a gap for the label in RTL */
    }
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    background-color: #9244eb !important;
  }

  .text-danger.error {
    [dir='rtl'] & {
      text-align: right; /* Align error message for RTL */
    }
  }
}

.react-datepicker__day {
  width: 2.7rem !important;
  line-height: 2.7rem !important;
  text-align: center;
  margin: 0.7rem !important;
  font-size: 14px !important;
}
